import React from 'react';
// import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Table } from 'semantic-ui-react';
// import { Button, Icon, Table } from 'semantic-ui-react';

import MyNumberInput from '../../../../app/common/form/MyNumberInput';

import {
	calRegTaxFamily,
	calRegTax1000Over,
	healthCare,
	noJobInsurance,
	numberToRoundCommaString,
	commaStringToNumber,
	oldCare,
	tax1000Over,
	pension,
	taxIncludeFamily,
} from '../../../../app/common/util/util';
import { toast } from 'react-toastify';
// import { afterHealthCareBaseWageAction, afterPensionBaseWageAction, afterTaxWageAction, afterNoJobBaseWageAction , sumTaxFreeWageAction, afterNumOfTaxFamilyAction, afterNumOfTaxFamily7To20Action } from '../clientReqActions4In';
import { format } from 'date-fns';
// import { openModal } from '../../../../app/common/modals/modalReducer';

export default function NetWageCal() {
	// const dispatch =useDispatch();
	const [totalTaxWage, setTotalTaxWage] = React.useState(0);
	const [sumTaxFreeWage, setSumTaxFreeWage] = React.useState(0);
	const [pensionBaseWage, setPensionBaseWage] = React.useState(0);
	const [healthCareBaseWage, setHealthCareBaseWage] = React.useState(0);
	const [noJobBaseWage, setNoJobBaseWage] = React.useState(0);
	const [taxFamily, setTaxFamily] = React.useState(1);
	const [taxFamily7To20, setTaxFamily7To20] = React.useState(0);

	// const resultAfterTaxWage = useSelector(state => state.clientReq4InStore.totalTaxWage);
	// const resultSumTaxFreeWage = useSelector(state => state.clientReq4InStore.sumTaxFreeWage);
	// const resultPensionBaseWage = useSelector(state => state.clientReq4InStore.pensionBaseWage);
	// const resultHealthBaseWage = useSelector(state => state.clientReq4InStore.healthCareBaseWage);
	// const resultNoJobBaseWage = useSelector(state => state.clientReq4InStore.noJobBaseWage);
	// const resultNumberOfTaxFamily = useSelector(state => state.clientReq4InStore.numOfTaxFamily);
	// const resultNumberOfTaxFamily7To20 = useSelector(state => state.clientReq4InStore.numOfTaxFamily7To20);

	const numPensionBaseWage = commaStringToNumber(pensionBaseWage);
	const numHealthBaseWage = commaStringToNumber(healthCareBaseWage);
	const numNoJobBaseWage = commaStringToNumber(noJobBaseWage);
	const numOfTaxFamily = commaStringToNumber(taxFamily);
	const numOfTaxFamily7To20 = commaStringToNumber(taxFamily7To20);

	const resultTaxWage =
		commaStringToNumber(totalTaxWage) - commaStringToNumber(sumTaxFreeWage);

	// const test = taxIncludeFamily(1500000, 0)
	// console.log("test", test)

	// // console.log(taxTable2021)

	//Net 계산(세후 실수령액 계산)
	const year = parseInt(format(new Date(), 'yyyy-MM-dd').split('-')[0]);
	const month = parseInt(format(new Date(), 'yyyy-MM-dd').split('-')[1]);

	const afterTaxWage = (totalMonthWageAmount) => {
		// 필요한 초기 변수 설정
		const healthBaseWage = numHealthBaseWage;
		const noJobBaseWage = numNoJobBaseWage;
		const pensionBaseWage = numPensionBaseWage;

		// 이진 탐색을 위한 초기 범위 설정
		let low = 0;
		let high = 999999999;
		let mid, targetWage;

		while (low <= high) {
			mid = Math.floor((low + high) / 2);
			const afterPensionTarget = pensionBaseWage >= 0 ? pensionBaseWage : mid;
			const afterHealthCareTarget = healthBaseWage >= 0 ? healthBaseWage : mid;
			const afterNoJobTarget = noJobBaseWage >= 0 ? noJobBaseWage : mid;
			const calTax1000 =
				mid < 10000000
					? taxIncludeFamily(mid, numOfTaxFamily, numOfTaxFamily7To20)
					: Math.floor(
							tax1000Over(mid, numOfTaxFamily, numOfTaxFamily7To20) / 10
					  ) * 10;
			const calRegTax1000 =
				mid < 10000000
					? calRegTaxFamily(mid, numOfTaxFamily, numOfTaxFamily7To20)
					: calRegTax1000Over(mid);

			let sumTaxEtc =
				calTax1000 +
				calRegTax1000 +
				pension(afterPensionTarget, year, month) +
				healthCare(afterHealthCareTarget, year) +
				oldCare(afterHealthCareTarget, year) +
				noJobInsurance(afterNoJobTarget, year, month);

			targetWage = mid - sumTaxEtc;
			if (Math.round(targetWage) === Math.round(totalMonthWageAmount)) {
				return Math.round(mid / 10) * 10;
			} else if (targetWage < totalMonthWageAmount) {
				low = mid + 1;
			} else {
				high = mid - 1;
			}
		}

		return null; // 적절한 값이 없을 경우
	};

	const beforeTaxWage = afterTaxWage(resultTaxWage);
	const beforePensionTarget =
		numPensionBaseWage >= 0 ? numPensionBaseWage : beforeTaxWage;
	const beforeHealthCareTarget =
		numHealthBaseWage >= 0 ? numHealthBaseWage : beforeTaxWage;
	const beforeNoJobTarget =
		numNoJobBaseWage >= 0 ? numNoJobBaseWage : beforeTaxWage;
	const calTax1000 =
		beforeTaxWage < 10000000
			? taxIncludeFamily(beforeTaxWage, numOfTaxFamily, numOfTaxFamily7To20)
			: Math.floor(
					tax1000Over(beforeTaxWage, numOfTaxFamily, numOfTaxFamily7To20) / 10
			  ) * 10;
	const calRegTax1000 =
		beforeTaxWage < 10000000
			? calRegTaxFamily(beforeTaxWage, numOfTaxFamily, numOfTaxFamily7To20)
			: calRegTax1000Over(beforeTaxWage);
	const pensionR = pension(beforePensionTarget, year, month);
	const healthCareR = healthCare(beforeHealthCareTarget, year);
	const oldCareR = oldCare(beforeHealthCareTarget, year);
	const noJobInsur = noJobInsurance(beforeNoJobTarget, year, month);
	const deductSum =
		calTax1000 + calRegTax1000 + pensionR + healthCareR + oldCareR + noJobInsur;
	const afterWage =
		beforeTaxWage - deductSum + commaStringToNumber(sumTaxFreeWage);
	const sumWage = beforeTaxWage + commaStringToNumber(sumTaxFreeWage);
	const noticeDay = new Date();

	// function payPaperNetCalYTOpen() {
	//   dispatch(openModal({modalType:'PayPaperNetCal'}))
	// }

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					alignContent: 'center',
				}}>
				<span style={{ fontSize: '20px', color: 'teal', cursor: 'pointer' }}>
					Net 월급(세후 수령액) 계산기
				</span>
				<span style={{ fontSize: '12x', color: 'red', cursor: 'pointer' }}>
					(주의 : 월급 1억이하만 입력)
				</span>
				{/* <span style={{color:'red', position: "absolute", right: 0, marginRight:'20px', cursor:'pointer'}} onClick={() => payPaperNetCalYTOpen()}><Icon size='big' name='youtube' />실수령액 계산기 동영상 설명</span> */}
			</div>
			<>
				<hr />
				<Formik
					initialValues={{
						totalMonthWageAmount: '',
						sumTaxFreeWage: 0,
						numOfTaxFamily: 1,
						numOfTaxFamily7To20: 0,
						pensionBaseWage: '',
						healthCareBaseWage: '',
						noJobBaseWage: '',
					}}
					validationSchema={Yup.object({
						totalMonthWageAmount: Yup.string().required(
							'비과세를 포함한 전체 월급을 입력하세요.'
						),
						sumTaxFreeWage: Yup.string().required(
							'비과세가 없으면 "0"을 입력하세요.'
						),
						numOfTaxFamily: Yup.string().required(
							'부양가족이 없으면 "1"을 입력하세요.'
						),
						numOfTaxFamily7To20: Yup.string().required(
							'부양가족이 없으면 "0"을 입력하세요.'
						),
					})}
					onSubmit={(values, { setSubmitting }) => {
						try {
							setSubmitting(true);
							setTotalTaxWage(values.totalMonthWageAmount);
							setSumTaxFreeWage(values.sumTaxFreeWage);
							setPensionBaseWage(values.pensionBaseWage);
							setHealthCareBaseWage(values.healthCareBaseWage);
							setNoJobBaseWage(values.noJobBaseWage);
							setTaxFamily(values.numOfTaxFamily);
							setTaxFamily7To20(values.numOfTaxFamily7To20);

							// dispatch(afterTaxWageAction(values.totalMonthWageAmount));
							// dispatch(sumTaxFreeWageAction(values.sumTaxFreeWage));
							// dispatch(afterPensionBaseWageAction(values.pensionBaseWage));
							// dispatch(afterHealthCareBaseWageAction(values.healthCareBaseWage));
							// dispatch(afterNumOfTaxFamilyAction(values.numOfTaxFamily));
							// dispatch(afterNumOfTaxFamily7To20Action(values.numOfTaxFamily7To20));
							// dispatch(afterNoJobBaseWageAction(values.noJobBaseWage));
							setSubmitting(false);
						} catch (error) {
							console.log(error.message);
							toast.error(error.message);
							setSubmitting(false);
						}
					}}>
					{({ isSubmitting, isValid, dirty }) => (
						<Form className='ui form'>
							<div
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									justifyContent: 'flex-start',
									alignItems: 'center',
									alignContent: 'flex-end',
								}}>
								<div
									className='margin'
									style={{ width: '16%', marginRight: '3px' }}>
									<MyNumberInput
										label='실수령액 총액(비과세 포함)(원)'
										name='totalMonthWageAmount'
										placeholder='과세+비과세'
										autoComplete='off'
									/>
								</div>
								<div
									className='margin'
									style={{ width: '16%', marginRight: '3px' }}>
									<MyNumberInput
										label='비과세 합계(원)'
										name='sumTaxFreeWage'
										placeholder='비과세 금액 합계'
										autoComplete='off'
									/>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									justifyContent: 'flex-start',
									alignItems: 'center',
									alignContent: 'flex-end',
								}}>
								<div
									className='margin'
									style={{ width: '16%', marginRight: '3px' }}>
									<MyNumberInput
										label='(소득세)부양가족수(본인포함)'
										name='numOfTaxFamily'
										placeholder='없으면 1'
										autoComplete='off'
									/>
								</div>
								<div
									className='margin'
									style={{ width: '16%', marginRight: '3px' }}>
									<MyNumberInput
										label='8세이상 20세이하 부양가족수'
										name='numOfTaxFamily7To20'
										placeholder='없으면 0'
										autoComplete='off'
									/>
								</div>
								<div
									className='margin'
									style={{ width: '16%', marginRight: '3px' }}>
									<MyNumberInput
										label='국민연금 기준보수월액(원)'
										name='pensionBaseWage'
										placeholder='해당시 입력(적용제외시 0 입력)'
										autoComplete='off'
									/>
								</div>
								<div
									className='margin'
									style={{ width: '19.5%', marginRight: '3px' }}>
									<MyNumberInput
										label='건강보험료 평균보수월액(원)'
										name='healthCareBaseWage'
										autoComplete='off'
										placeholder='해당시 입력'
									/>
								</div>
								<div
									className='margin'
									style={{ width: '16%', marginRight: '3px' }}>
									<MyNumberInput
										label='고용보험 기준보수월액(원)'
										name='noJobBaseWage'
										autoComplete='off'
										placeholder='해당시 입력(적용제외시 0 입력)'
									/>
								</div>
							</div>
							<br />
							<div style={{ fontSize: '12x', marginTop: '-15px' }}>
								* 본 세후계산기는 참고용 계산기임.{' '}
								<span style={{ color: 'blue' }}>
									(보수월액이 있는 경우 그 보수월액, 적용제외자(연금:60세이상,
									고용:65세이상 등)는 보수월액에 0 원을 입력하세요.)
								</span>
							</div>
							<br />
							<Button
								loading={isSubmitting}
								disabled={isSubmitting || !isValid || !dirty}
								floated='left'
								type='submit'
								size='large'
								positive
								content='계산하기'
							/>
						</Form>
					)}
				</Formik>

				<br />
				<br />

				<h5>{`* 아래 계산은 ${format(
					noticeDay,
					'yyyy-MM-dd'
				)} 현재 4대보험 및 세금 기준 계산임을 안내해 드립니다.`}</h5>
				<hr />

				<Table style={{ textAlign: 'center' }}>
					<Table.Body>
						<Table.Row>
							<Table.Cell>과세총액</Table.Cell>
							<Table.Cell>비과세</Table.Cell>
							<Table.Cell>월급합계</Table.Cell>
							<Table.Cell>근로소득세</Table.Cell>
							<Table.Cell>지방소득세</Table.Cell>
							<Table.Cell>국민연금</Table.Cell>
							<Table.Cell>건강</Table.Cell>
							<Table.Cell>요양</Table.Cell>
							<Table.Cell>고용</Table.Cell>
							<Table.Cell>공제계</Table.Cell>
							<Table.Cell>실수령액</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								{beforeTaxWage === undefined
									? ''
									: numberToRoundCommaString(beforeTaxWage)}
							</Table.Cell>
							<Table.Cell>
								{typeof sumTaxFreeWage === 'string'
									? sumTaxFreeWage
									: numberToRoundCommaString(sumTaxFreeWage)}
							</Table.Cell>
							<Table.Cell>
								{sumWage ? numberToRoundCommaString(sumWage) : 0}
							</Table.Cell>
							<Table.Cell>
								{calTax1000 ? numberToRoundCommaString(calTax1000) : 0}
							</Table.Cell>
							<Table.Cell>
								{calRegTax1000 ? numberToRoundCommaString(calRegTax1000) : 0}
							</Table.Cell>
							<Table.Cell>
								{pensionR ? numberToRoundCommaString(pensionR) : 0}
							</Table.Cell>
							<Table.Cell>
								{healthCareR ? numberToRoundCommaString(healthCareR) : 0}
							</Table.Cell>
							<Table.Cell>
								{oldCareR ? numberToRoundCommaString(oldCareR) : 0}
							</Table.Cell>
							<Table.Cell>
								{noJobInsur ? numberToRoundCommaString(noJobInsur) : 0}
							</Table.Cell>
							<Table.Cell>
								{deductSum ? numberToRoundCommaString(deductSum) : 0}
							</Table.Cell>
							<Table.Cell>
								{afterWage ? numberToRoundCommaString(afterWage) : 0}
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
				<hr />
			</>
		</>
	);
}
