import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PriceLaborContractType from '../../../auth/PriceLaborContractType';
import useScrollRestoration from '../../../../app/hooks/useScrollRestoration';

export default function ClientReqDashboard4InLaborContractsInfo() {
	const { saveScrollPosition, restoreScrollPosition } = useScrollRestoration();

	const { currentUserProfile } = useSelector((state) => state.profile);
	const [openFeaturesTable, setOpenFeaturesTable] = useState(true);
	const isWorker = currentUserProfile?.isWorker;

	useEffect(() => {
		restoreScrollPosition();

		return () => {
			saveScrollPosition();
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{isWorker ? null : (
				<>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							alignContent: 'center',
						}}
						onClick={() => setOpenFeaturesTable(!openFeaturesTable)}>
						<span style={{ fontSize: '23px', cursor: 'pointer' }}>
							<span>
								직원별 입사정보 등록과 동시에{' '}
								<span style={{ color: 'red' }}>
									0.1초만에 구분하여 만들어지는 SafeHR의 11종 근로계약서(안)
								</span>{' '}
								종류
							</span>
						</span>
					</div>
					<br />
					{openFeaturesTable && (
						<PriceLaborContractType
							openFeaturesTable={openFeaturesTable}
							setOpenFeaturesTable={setOpenFeaturesTable}
						/>
					)}
				</>
			)}
		</>
	);
}
