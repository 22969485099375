import React from 'react';
import {
	getAgeFromSocialNumber,
	traineeEndDateValue,
	workerEnteredDateValue,
} from '../../../../../app/common/util/util';
import { contractOldWorkerOrNot } from '../../../../../app/api/dropdownOption';
import MySelectInput from '../../../../../app/common/form/MySelectInput';
// import { Icon, Label } from 'semantic-ui-react';
// import { useDispatch } from 'react-redux';
// import { openModal } from '../../../../../app/common/modals/modalReducer';
export default function WorkerFormStep1ContractType({ values, MyDateInput }) {
	// const dispatch = useDispatch();

	const contractOldWorkerSelect =
		values?.contractOldWorker === '정년이후 촉탁계약직' ? true : false;

	const netAge = getAgeFromSocialNumber(values.workerSocialNumberFront);

	// function workerRegStep1_2YTOpen() {
	// 	dispatch(openModal({ modalType: 'WorkerRegStep1_2' }));
	// }

	// function workerRegStep1_3YTOpen() {
	// 	dispatch(openModal({ modalType: 'WorkerRegStep1_3' }));
	// }

	// function workerRegStep1_4YTOpen() {
	// 	dispatch(openModal({ modalType: 'WorkerRegStep1_4' }));
	// }

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				{/* <div
					style={{
						display: 'flex',
						alignItems: 'center',
						alignContent: 'flex-end',
						width: '100%',
						marginBottom: '30px',
						marginTop: '30px',
						fontSize: '17px',
					}}>
					<span style={{ color: 'red' }}>* </span>
					<span style={{ color: 'blue' }}>계약직/정년촉탁직/수습직 설정</span>
					<span style={{ fontSize: '8px' }}>
						(설명은 오른쪽 동영상을 참조하세요.)
					</span>
					<span
						style={{
							position: 'absolute',
							right: 0,
							marginRight: '10px',
							cursor: 'pointer',
						}}>
						<Label
							style={{ color: 'red' }}
							onClick={() => workerRegStep1_4YTOpen()}>
							<Icon size='big' name='youtube' />
							촉탁직 설정
						</Label>
					</span>
					<span
						style={{
							position: 'absolute',
							right: 0,
							marginRight: '140px',
							cursor: 'pointer',
						}}>
						<Label
							style={{ color: 'red' }}
							onClick={() => workerRegStep1_3YTOpen()}>
							<Icon size='big' name='youtube' />
							수습직 설정
						</Label>
					</span>
					<span
						style={{
							position: 'absolute',
							right: 0,
							marginRight: '270px',
							cursor: 'pointer',
						}}>
						<Label
							style={{ color: 'red' }}
							onClick={() => workerRegStep1_2YTOpen()}>
							<Icon size='big' name='youtube' />
							계약직 설정
						</Label>
					</span>
				</div> */}
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<div className='tooltip'>
						<MySelectInput
							label='(1) 정년이후 촉탁직 설정'
							name='contractOldWorker'
							placeholder='고령자 관련법에 따라 만 60세 이후인 경우 활용'
							options={contractOldWorkerOrNot}
						/>

						{netAge >= 60 &&
						values.contractOldWorker === '정년이후 촉탁계약직' ? (
							<span style={{ color: 'red' }}>
								주의 : 하단 3-1~3-3 "정년 퇴직이후 촉탁계약직"에 날짜정보를
								입력하세요.
							</span>
						) : null}
						{console.log(
							'netAge',
							netAge < 60 && values.contractOldWorker === '정년이후 촉탁계약직'
						)}

						<span className='tooltiptext'>
							고령자 관련법에 따라 만 60세 이상인 경우 활용하시고, 선택시
							계약기간/연차/퇴직금이 단절되는 근로계약서가 설계됩니다.
						</span>
						{netAge < 60 &&
						values.contractOldWorker === '정년이후 촉탁계약직' ? (
							<span style={{ color: 'blue' }}>
								{`* ${values?.worker?.name}의 만 나이는 ${netAge}세 입니다. `}
							</span>
						) : null}
						<br />
						{netAge < 60 &&
						values.contractOldWorker === '정년이후 촉탁계약직' ? (
							<span style={{ color: 'brown' }}>
								{`- 고령자 관련법에 따라 만 60세 이상인 경우 활용하시고, 선택시
								계약기간/연차/퇴직금이 단절되는 근로계약서가 설계됩니다.`}
							</span>
						) : null}
					</div>
				</div>

				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						name='contractEndDate'
						label='(2) 계약직 근로계약 종료일'
						placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						name='againContractStartDate'
						label={
							<span style={{ fontWeight: 'bold', color: '#007bff' }}>
								(3) 계약직 2차이상 재 계약일(갱신계약 시작일)
							</span>
						}
						placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div
					style={{
						width: '100%',
						marginBottom: '10px',
						marginTop: '50px',
						fontSize: '13px',
					}}>
					● <span style={{ color: 'teal' }}>수습직</span>인 경우
				</div>
				<div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
					<MyDateInput
						label='(2-1) 수습시작일'
						name='traineeStartDate'
						placeholderText={workerEnteredDateValue(values)}
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
					<MyDateInput
						label='(2-2) 수습종료일'
						name='traineeEndDate'
						placeholderText={traineeEndDateValue(values)}
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				{values.contractOldWorker === '정년이후 촉탁계약직' ? (
					<div
						style={{
							width: '100%',
							marginBottom: '10px',
							marginTop: '50px',
							fontSize: '13px',
						}}>
						● <span style={{ color: 'red' }}>정년 퇴직 이후 촉탁 계약직</span>
					</div>
				) : (
					<div
						style={{
							width: '100%',
							marginBottom: '10px',
							marginTop: '50px',
							fontSize: '13px',
						}}>
						● <span style={{ color: 'teal' }}>정년 퇴직 이후 촉탁 계약직</span>
					</div>
				)}
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						name='oldRetireDate'
						label='(3-1) 정년퇴직일'
						placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
						disabled={!contractOldWorkerSelect}
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						name='afterRetireAgainContractStartDate'
						label='(3-2) 정년퇴직 이후 촉탁 계약직 재계약일(새로운 근로계약 시작일)'
						placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
						disabled={!contractOldWorkerSelect}
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						name='afterRetireAgainContractEndDate'
						label='(3-3) 정년퇴직 이후 촉탁 계약직 근로계약 종료일'
						placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
						disabled={!contractOldWorkerSelect}
					/>
				</div>
			</div>
		</>
	);
}
