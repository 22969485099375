import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function WorkerRegStep3_1() {
	const opts = {
		// height: '300%',
		width: '100%',
		playVars: {
			autoplay: 1,
		},
	};

	return (
		<ModalWrapper size='large' header='SafeHR 근로소득세 및 4대보험료 설정'>
			<YouTube videoId='miv3GVJAL9Y' opts={opts} />
		</ModalWrapper>
	);
}
