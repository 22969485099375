import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function WorkerRegStep3_3() {
	const opts = {
		// height: '300%',
		width: '100%',
		playVars: {
			autoplay: 1,
		},
	};

	return (
		<ModalWrapper size='large' header='SafeHR 평균임금 설정'>
			<YouTube videoId='0u-ad8Kwa8Q' opts={opts} />
		</ModalWrapper>
	);
}
