import React from 'react';
import { Button } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { josaGaType, laborContractStyle } from '../../app/common/util/util';

export default function ClientUseContractPaperIntro() {
	const companyGa = josaGaType('카아소프트');
	return (
		<>
			<div>제정일 : 2024년 09월 15일</div>

			<div style={laborContractStyle}>
				<strong>제1장 총칙</strong>
				<br />
				<br />
				<div className='margin'>제1조. 목적</div>
				<div className='margin'>
					본 약관은 {companyGa} 제공하는 SafeHR 서비스{' '}
					<span style={{ color: 'blue' }}>
						(본 서비스는 인사급여 관리 프로그램으로 일정 알로리즘을 통해
						근로계약서, 임금대장 등 노동법상 서류가 설계되고 작성되는 부분이
						있으나, 노동법에 부합하는지에 대한 확인은 불가능(공인노무사법 제2조
						제1항 제2호("노동관계법령에 따른 서류의 작성과 확인")에 따라 작성과
						확인이 동시에 이루어지는 서비스는 공인노무사만 가능함.)하므로 본
						서비스를 통해 산출되는 결과물은 법적 효력을 갖는 유권해석(결정,
						판단)이 아님을 명백히 안내해드리며, 입력 결과에 대하여는
						사용자(회원)가 충분한 검토와 확인을 하시고, 노동법은 공인노무사,
						세법은 세무사나 회계사 등 관련 법령에 의해 법률 해석 및 확인에 대한
						적법한 권한을 가진 전문가의 조력과 확인받을 것을 강력히 권고하며, 본
						서비스 이용으로 인한 책임은 회원이 부담함)
					</span>{' '}
					이용과 관련하여, 회사와 회원의 권리, 의무 및 책임 사항, 기타 필요한
					사항을 규정 함을 목적으로 합니다.
				</div>
				<br />
				<div className='margin'>
					제2조. 용어의 정의 및 공인노무사법, 세무사법 등에 대한 주의사항
				</div>
				<div className='margin'>
					본 약관에서 사용하는 용어의 정의 및 공인노무사법, 세무사법 등에 대한
					주의사항은 다음과 같습니다.
				</div>
				<div className='margin'>
					1. "서비스" 라 함은 모바일 및 PC 온라인 상으로 근로계약서, 인사 정보,
					임금명세서 등의 인사노무 업무를 수행할 수 있도록 회사가 제공하는 종합
					인사노무 관리 전산 이용권한을 제공하는 서비스를 말합니다.
				</div>
				{/* <div className='margin'>2. "고급형(Premium) 서비스" 라 함은 기본형 서비스에 추가하여 취업규칙, 노사협의회 규정 등 기본형에서 제공하지 않는 정형화된 전산 기능이 추가된 전산 서비스를 말합니다.</div> */}
				<div className='margin'>
					2. "회원" 이라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사와
					유무상 이용 계약을 체결하고 회사의 서비스를 이용하는 기업회원,
					근로자회원, 노무사회원 등 자연인 또는 법인을 말합니다.
				</div>
				<div className='margin'>
					3. "유료 회원" 이라 함은 본인 또는 본인의 고객 등을 위해 별도의 금액을
					지불하고 유료 서비스 또는 부가 유료 서비스를 이용하는 회원을
					의미합니다.
				</div>
				<div className='margin'>
					4. "무료 회원" 이라 함은 근로자 회원 등 본인은 별도의 금액을 지불하지
					않고(본인 및 본인의 근로자 회원을 위해 권한있는 대리인이나 광고주가
					대신 납부하는 경우를 포함.), 정해진 전산 서비스를 이용하는 회원을
					의미합니다.
				</div>
				<div className='margin'>
					5. "회원ID" 이라 함은 회원의 식별과 회원의 서비스 이용을 위하여 회원이
					입력 혹은 등록한 이메일 주소를 말합니다.
				</div>
				<div className='margin'>
					6. "비밀번호" 이라 함은 회사의 서비스를 이용하려는 사람이 회원ID를
					부여 받은 자와 동일인 임을 확인하고 회원의 권익을 보호하기 위하여
					회원이 선정(권한있는 대리인이 비밀번호를 선정한 경우 회원 본인이
					인계받아 동 비밀번호를 사용하거나 변경한 경우를 포함.)한 경우 문자
					또는 문자와 숫자 등의 조합을 말하며, 회사도 식별하지 못하므로 분실시
					일정한 요건에 걸처 회원 본인 임이 확인되면 회사가 비밀번호 재설정
					요청하고 회원이 다시 선정한 문자 또는 문자와 숫자 등의 조합을
					말합니다.
				</div>
				<div className='margin'>
					7. "개인정보" 라 함은 서비스를 이용하기 위하여 회원이 제공하여
					서비스에 회원이 근로기준법 시행령 제22조 필요적 서류에 기재되는 기업
					회원 본인 및 기업 회원 직원들의 개인정보를 말합니다.
				</div>
				<div className='margin'>
					8. 본 시스템에서 분류하는 "단시간 근로자" 라 함은 근로기준법 제50조
					제1항의 1주 40시간미만 15시간 이상의 소정근로시간을 근무하는 근로자를
					말합니다.
				</div>
				<div className='margin'>
					9. 본 시스템에서 분류하는 "초단시간 근로자" 라 함은 근로기준법 제18조
					제3항의 1주 평균 15시간 미만의 소정근로시간을 근무하는 근로자를
					말합니다.
				</div>
				<div className='margin'>
					10. 본 시스템에서 분류하는 "정규직 근로자" 라 함은 1주 40시간의
					소정근로시간을 근무하는 근로자를 말합니다.
				</div>
				<div className='margin'>
					11. 본 시스템에서 분류하는 "위 출퇴근시간이 정해진 근무시간외에 사무직
					등 업무 특성상 근무일이 불특정한 연장,야간 근로가 일정하게 있는
					경우(1주일단위)" 라 함은 고객이 근무일별로 입력한 근무시간이
					주40시간을 초과하던 안하던 근로기준법 제56조의 가산입금 대상이 되는
					연장근로와 야간근로으로 간주하여 임금을 계산하는 대상 시간을 말합니다.
				</div>
				<div className='margin'>
					12. 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을
					제외하고는 관련 법령에서 정하는 바에 의하며, 관련 법령에서 정하지 않는
					것은 일반적인 상관습에 따릅니다.
				</div>
				<div className='margin'>
					{' '}
					<span style={{ color: 'blue' }}>
						13. 본 서비스는 인사급여 관리 프로그램으로 일정 알로리즘을 통해
						근로계약서, 임금대장 등 노동법상 서류가 설계되는 부분이 있으나,
						노동법에 부합하는지에 대한 확인은 불가능(공인노무사법 제2조 제1항
						제2호("노동관계법령에 따른 서류의 작성과 확인")에 따라 작성과 확인이
						동시에 이루어지는 서비스는 공인노무사만 가능함.)하므로 본 서비스를
						통해 산출되는 결과물은 법적 효력을 갖는 유권해석(결정, 판단)이
						아님을 명백히 안내해드리며, 입력 결과에 대하여는 사용자(회원)가
						충분한 검토와 확인을 하시고, 노동법은 공인노무사, 세법은 세무사나
						회계사 등 관련 법령에 의해 법률 해석 및 확인에 대한 적법한 권한을
						가진 전문가의 조력과 확인받을 것을 강력히 권고하며, 본 서비스
						이용으로 인한 책임은 회원이 부담함을 안내해 드립니다.
					</span>
				</div>
				<div className='margin'>
					{' '}
					<span style={{ color: 'blue' }}>
						14. 위 13호의 특징에 따라 회사는 공인노무사법상 개업 노무사들과
						협업을 진행하고 있으며, 회원이 당사 협력 노무사와 SafeHR 유료 서비스
						이용계약을 체결해도 회원은 회사와 회원간 본 약관에 따른 유료 서비스
						이용계약도 체결한 것으로 간주됨을 안내해 드립니다.
					</span>
				</div>
				<br />
				<div className='margin'>제3조. 약관의 게시와 개정 </div>
				<div className='margin'>
					1. 회사는 이 약관의 내용과 상호, 대표자의 성명, 사업자등록번호, 연락처
					등을 회원이 알 수 있도록 서비스 초기화면에 게시하거나 기타의 방법으로
					사용자에게 공지합니다.
				</div>
				<div className='margin'>
					2. 회사는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법,
					정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령을 위배하지
					않는 범위에서 이 약관을 개정할 수 있습니다.
				</div>
				<div className='margin'>
					3. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행
					약관과 함께 개정약관의 적용일자 7일 이전부터 적용일자 전일까지
					공지합니다. 단 회원의 권리, 의무에 중대한 영향을 주는 변경의 경우에는
					최소한 30일 이상의 유예기간을 두고 공지합니다. 회원ID의 이메일 주소
					변경 등으로 인하여 개별 통지가 어려운 경우, 회원ID의 이메일 주소로
					공지를 하였음에도 반송된 경우 이 약관에 의한 공지를 함으로써 개별
					통지한 것으로 간주합니다.
				</div>
				<div className='margin'>
					4. 회원은 변경된 약관에 대해 거부할 권리가 있습니다. 회사가 제3항의
					공지 또는 통보를 하면서 개정 약관의 적용·시행일까지 회원이 거부 의사를
					표시하지 아니할 경우 약관의 개정에 동의한 것으로 간주한다는 내용을
					고지하였으나, 회원이 명시적으로 약관 변경에 대한 거부 의사를 표시하지
					아니하면, 회사는 회원이 적용·시행 일자 부로 변경 약관에 동의한 것으로
					간주합니다. 개정·변경 약관에 대하여 거부의사를 표시한 회원은 계약의
					해지 또는 회원 탈퇴를 선택할 수 있습니다.
				</div>
				<br />
				<div className='margin'>제4조. 약관의 해석 </div>
				<div className='margin'>
					1. 회원이 회사와 개별 계약을 체결하여 서비스를 이용하는 경우 회사는
					개별 서비스에 대한 이용약관 또는 운영정책 등(이하 ‘운영정책 등’이라
					함)을 둘 수 있으며, 해당 내용이 본 약관과 상충되는 경우 개별서비스에
					대한 운영정책 등이 우선합니다.
				</div>
				<div className='margin'>
					2. 이 약관에서 규정하지 않은 사항에 관해서는 약관의 규제에 관한 법률,
					전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에
					관한 법률 등의 관계법령에 따릅니다.
				</div>
				<br />
				<div className='margin'>제5조. 이용 계약의 성립</div>
				<div className='margin'>
					1. 회사와 회원 사이의 서비스 이용계약(이하 ‘이용계약’이라 함)은
					서비스를 이용하고자 하는 자(이하 ‘가입신청자’라 함)의 이용신청 및 본
					약관과 개인정보처리방침의 내용에 대한 동의와 이에 대한 회사의
					이용승낙(사용권한 부여)으로 성립합니다.
				</div>
				<div className='margin'>
					2. 본 약관 및 개인정보처리방침에 대한 동의의 의사표시는 서비스 신청,
					회원가입, 정보 제출 등의 버튼을 누름으로써 이와 동시에 위 가입신청자가
					본 약관 및 개인정보 처리 방침에 대하여 동의한 것으로 간주합니다.
				</div>
				<div className='margin'>
					3. 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로
					합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지
					않거나 사후에 이용계약을 해지할 수 있습니다.{' '}
				</div>
				<div className='margin'>
					3-1. 가입신청자가 이 약관에 의하여 이전에 회원 자격을 상실한 적이 있는
					경우
				</div>
				<div className='margin'>
					3-2. 실명이 아니거나 타인의 명의를 이용한 가입 신청의 경우
				</div>
				<div className='margin'>
					3-3. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은
					경우
				</div>
				<div className='margin'>
					3-4. 만 14세 미만 아동이 정보통신망 이용촉진 및 정보보호 등에 관한
					법률에서 정한 개인정보 입력 시 법정대리인의 동의를 얻지 아니한 경우
				</div>
				<div className='margin'>
					3-5. 가입 신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한
					제반 사항을 위반하며 신청하는 경우
				</div>
				<div className='margin'>
					4. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가
					있는 경우에는 승낙을 유보할 수 있습니다.
				</div>
				<div className='margin'>
					5. 회사가 제2항부터 제4항에 따라 회원가입 신청을 승낙하지 아니하거나
					유보한 경우, 회사는 원칙적으로 그 사실을 가입 신청자에게 알리도록
					합니다.
				</div>
				<div className='margin'>
					6. 이용계약의 성립 시기는 회사가 이용 권한을 부여한 시점으로 합니다.
				</div>
				<div className='margin'>
					7. 회사는 회원에 대해 회사 정책에 따라 등급별로 구분하여 이용 시간,
					이용 횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
				</div>
				<div className='margin'>
					8. 이 약관은 회원이 이 약관에 동의한 날로부터 회원 탈퇴 시까지
					적용하는 것을 원칙으로 합니다. 단, 이 약관의 일부 조항은 회원이 탈퇴
					후에도 유효하게 적용될 수 있습니다.
				</div>
				<br />
				<div className='margin'>제6조. 회원 정보의 제공</div>
				<div className='margin'>
					1. 회원으로 가입하여 서비스를 이용하고자 하는 회원은 이메일, 상호,
					성명, 전화번호 등 회사가 화원 가입시 요청한 정보를 제공하여야 합니다.
				</div>
				<div className='margin'>
					2. 회원이 제1항에서 정한 인증을 거치지 않은 경우 서비스 이용이 제한될
					수 있으며, 실명으로 등록하지 않은 회원 또는 회원은 일체의 권리를
					주장할 수 없습니다.
				</div>
				<div className='margin'>
					3. 타인의 명의를 도용하여 이용신청을 한 회원의 모든 ID는 삭제되며,
					관계법령에 따라 처벌을 받을 수 있습니다. 단, 회사 협력 노무사가 기존
					회원 및 회원으로 예정된 회원과 본 서비스 이용계약을 한 경우 그 협력
					노무사가 이용신청한 경우는 예외로 한다.
				</div>
				<div className='margin'>
					4. 회사는 회원이 사용하기로 동의한 서비스를 제공하기 위하여 회원의
					직원 인사 정보를 취합/저장/분석할 수 있습니다.
				</div>
				<br />
				<div className='margin'>제7조. 개인 정보의 보호 및 관리</div>
				<div className='margin'>
					1. 회사는 관계 법령이 정하는 바에 따라 계정 정보를 포함한 회원의
					개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보 보호 및 사용에
					대해서는 회사가 별도로 고지하는 개인정보처리방침에 따릅니다. 다만,
					회사가 제공하는 공식 서비스 사이트 이외의 링크된 사이트에서는 회사의
					개인정보처리방침이 적용되지 않습니다.
				</div>
				<div className='margin'>
					2. 회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한
					모든 정보에 대해서는 일체의 책임을 지지 않습니다.
				</div>
				<div className='margin'>
					3. 회사는 서비스 제공과 관련하여 취득한 회원의 개인정보를 본인의 사전
					동의 없이 제3자에게 제공할 수 없으며 회사의 서비스 관련 업무 이외의
					목적으로 사용할 수 없습니다. 다만, 다음 각 호에 해당하는 경우에는
					그러하지 아니합니다.
				</div>
				<div className='margin'>
					3-1. 금융실명거래및비밀보장에관한법률, 신용정보의이용및보호에관한법률,
					전기통신기본법, 전기통신사업법, 지방세법, 소비자보호법, 한국은행법,
					형사소송법 등 법령에 특별한 규정이 있는 경우
				</div>
				<div className='margin'>
					3-2. 노동관계 법령, 4대보험관련 법령 및 소득세법 등의 서류작성, 신고
					및 납부상 필요한 경우
				</div>
				<div className='margin'>3-3. 정보통신윤리위원회의 요청이 있는 경우</div>
				<div className='margin'>
					3-4. 서비스 제공에 따른 요금정산 등 서비스 이용계약 이행을 위하여
					필요한 경우
				</div>
				<div className='margin'>
					3-5. 통계작성•학술연구 또는 시장조사를 위하여 필요한 경우로서 특정
					개인을 식별할 수 없는 형태로 제공하는 경우
				</div>
				<div className='margin'>
					3-6. 회사가 정한 일정한 기간 동안 이용요금 등을 미납하여
					신용정보사업자 또는 신용 정보집중기관에 제공하는 경우
				</div>
				<br />
				<div className='margin'>제8조. 회원의 개정(ID) 및 비밀번호</div>
				<div className='margin'>
					1. 회원은 서비스의 원활한 이용 및 회원의 정보보호, 서비스 이용안내
					등의 편의를 위하여 회원가 선정한 이메일 주소를 계정(ID)으로
					사용합니다. 다만, 회사는 회원의 계정(ID)이 반사회적이거나 미풍양속을
					해치거나 또는 운영자로 오인할 우려가 있는 경우 등에는 해당 ID의 사용을
					거부하거나 제한할 수 있습니다.
				</div>
				<div className='margin'>
					2. 회사는 계정정보를 통하여 당해 회원의 서비스 이용 가능 여부 등 제반
					회원 관리 업무를 수행합니다.
				</div>
				<div className='margin'>
					3. 회원은 자신의 계정정보를 선량한 관리자로서의 주의의무를 다하여
					관리해야 합니다. 회원이 본인의 계정정보를 소홀히 관리하거나 제3자에게
					이용을 승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습니다.
				</div>
				<div className='margin'>
					4. 회원은 회사가 정한 기준을 충족하는 범위 내에서 자유롭게 비밀번호를
					정할 수 있으며, 정해진 비밀번호는 회원이 원하는 경우 언제든지 변경이
					가능합니다.
				</div>
				<div className='margin'>
					5. 회원은 서비스의 이용을 위하여 사용하는 비밀번호에 대한 보호 및 관리
					책임을 부담합니다. 다만, 회사는 보안 등을 이유로 회원에게 정기적 또는
					비정기적으로 비밀번호의 변경을 권고할 수 있습니다.
				</div>
				<div className='margin'>
					6. 회원이 비밀번호를 분실한 경우 회사도 회원의 비밀번호를 알 수 없기
					때문에 일정한 확인(사업자등록증 제출 및 고용보험 가입명부, 담당자
					신분증 확인 등)을 통해 회원임이 검증되면 회사는 회원의 ID(이메일)로
					비밀번호 재설정 메일송부하여 회원이 직접 비밀번호를 재설정하여야
					본시스템 및 서비스를 이용가능합니다.
				</div>
				<br />
				<div className='margin'>제9조. 회원에 대한 통지</div>
				<div className='margin'>
					1. 회사가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한
					회원이 계정으로 사용하는 전자우편 주소 또는 전화번호로 할 수 있습니다.
				</div>
				<div className='margin'>
					2. 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 홈페이지 또는
					공지사항 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
				</div>
				<br />
				<div className='margin'>제10조. 전산 서비스의 제공 시간 및 중지</div>
				<div className='margin'>
					1. 회사는 회원의 회원가입을 승낙한 때부터 서비스를 개시합니다. 단,
					유료 등 일부 서비스의 경우, 회사의 필요에 따라 지정된 일자부터
					서비스를 제공할 수 있습니다.
				</div>
				<div className='margin'>
					2. 회사는 업무상 또는 기술상 특별한 지장이 없는 한 전산이용 서비스는
					연중무휴 1일 24시간 서비스를 제공합니다. 다만, 정보통신설비 등 시스템
					정기점검, 서버의 증설 및 교체, 각종 버그 패치, 새로운 서비스로의 교체
					등 운영상 필요한 경우, 일정기간 동안 서비스를 일시 중지시킬 수
					있습니다.
				</div>
				<div className='margin'>
					3. 제2항 단서의 경우 회사는 그 내용 및 시간을 홈페이지 또는 SafeHR
					system에 공지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한
					사유가 있는 경우 사후에 통지할 수 있습니다.
				</div>
				<div className='margin'>
					4. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
					정기점검시간은 서비스 제공 화면에 공시한 바에 따릅니다.
				</div>
				<br />
				<div className='margin'>제11조. SafeHR 시스템의 내용 및 가격정책</div>
				<Button color='blue' as={NavLink} to='/pricePolicyPaper'>
					가격정책 바로가기
				</Button>
				<br />
				<br />
				<div className='margin'>제12조. 정보의 제공 및 광고의 게재</div>
				<div className='margin'>
					1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를
					회사의 홈페이지에 게시하거나 전자우편 등을 이용하여 회원에게 제공할 수
					있습니다. 다만, 회원은 관련 법령에 따른 거래관련 정보, 고객센터 답변
					등을 제외한 정보의 전송에 대하여 언제든지 전자우편 등을 통하여 수신
					거절의 의사표시를 할 수 있습니다.
				</div>
				<div className='margin'>
					2. 회사는 본 서비스 등을 유지하기 위하여 광고를 게재할 수 있으며,
					회원은 서비스 이용 시 노출되는 광고 게재에 대하여 동의합니다.
				</div>
				<div className='margin'>
					3. 회사가 제공하는, 제3자가 주체인, 제2항의 광고에 회원이 참여하거나
					교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해서 회사는 어떠한
					책임도 부담하지 않습니다.
				</div>
				<div className='margin'>
					4. 회사는 적법하게 수집한 회원의 개인정보를 활용하여 제2항의 광고 등을
					메일주소 등을 활용하여 발송할 수 있으며, 회원이 원하지 않는 경우에는
					언제든지 수신을 거부할 수 있습니다.
				</div>
				<br />
				<div className='margin'>제13조. 권리의 귀속</div>
				<div className='margin'>
					1. 서비스 및 서비스 내 회사가 제작한 콘텐츠 등에 대한 저작권 기타
					지적재산권은 회사에 귀속됩니다. 회사에서 제공하는 콘텐츠라 함은
					회사에서 제공하는 모든 문자, 파일, 그래픽, 동영상 등 모든 것을
					의미합니다.
				</div>
				<div className='margin'>
					2. 회사는 회사가 정한 이용조건에 따라 서비스와 관련하여 회원에게
					계정(ID), 게시물 등을 이용할 수 있는 이용권한만을 부여하며, 회원은
					이를 회사의 사전 승인없이 어떠한 경우도 양도, 판매, 담보제공하는 등
					일체의 처분행위를 할 수 없습니다.
				</div>
				<br />
				<div className='margin'>제14조. 회원의 계약해제·해지 등</div>
				<div className='margin'>
					1. 회원은 홈페이지에 게재된 회사 연락처에 연락하거나 서비스 내 탈퇴
					기능이 있는 경우 이을 통해 탈퇴 신청을 할 수 있으며, 회사는 관련법령
					등에서 정하는 바에 따라 이를 즉시 처리하여야 합니다.
				</div>
				<div className='margin'>
					2. 회원이 계약을 해지할 경우, 관련법령 및 회사의 약관 등 회사의
					개인정보 처리방침에서 정한 바에 따라 회사가 회원 및 회원 직원들의
					개인정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터는
					소멸됩니다.
				</div>
				<div className='margin'>
					3. 회원이 해지의 명시적 의사표시가 있으면서 데이터의 제공을 서면으로
					요청한 경우는 요청한 데이터를 회원이 확보할 수 있는 기간을 1주일
					이내로 제공하여 시스템에서 PDF출력, Excel 다운로드 등 본 시스템에
					구현된 기능을 통해 데이터를 확보할 수 있는 시간을 부여합니다.
				</div>
				<br />
				<div className='margin'>제15조. 이용제한 등</div>
				<div className='margin'>
					1. 회사는 회원이 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을
					방해한 경우, 서비스 이용을 경고, 일시정지, 계약해지로 단계적으로
					제한할 수 있습니다.
				</div>
				<div className='margin'>
					2. 회사는 제1항의 규정에도 불구하고, 주민등록법을 위반한 명의도용 및
					결제 도용, 저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의
					제공 및 운영 방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을
					위반한 불법 통신 및 해킹, 악성프로그램의 배포, 접속 권한 초과 행위
					등과 같이 관련법령을 위반한 경우에는 즉시 계약을 해지 할 수 있습니다.
					이에 따른 계약해지 시 서비스 이용을 통해 획득한 혜택 등은 모두
					소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.
				</div>
				<div className='margin'>
					3. 회사가 위 제1항에 따라 회원의 서비스 이용을 제한하거나 계약을
					해지하는 경우, 제한의 조건 및 세부 내용은 이용제한 정책 등에서 정한
					바에 따르며, 회사는 본 약관 제 9조에서 정한 방법으로 통지합니다.
				</div>
				<div className='margin'>
					4. 회원은 회사의 이용제한 조치 등에 대하여 회사가 정한 절차에 따라
					이의신청을 할 수 있습니다. 회원의 이의 사유가 정당하다고 인정되는 경우
					회사는 즉시 회원의 서비스 이용을 재개하여야 합니다.
				</div>
			</div>
			<div style={laborContractStyle}>
				<strong>제2장 유료 서비스의 이용</strong>
				<br />
				<br />
				<div className='margin'>제16조. 유료 서비스 이용 계약</div>
				<div className='margin'>
					1. 회사가 유료 회원의 이용 신청을 승낙한 때(일부 서비스의 경우, 이용
					신청 접수 후 사전 공지한 지정된 일자)로부터 유료 서비스는 개시되며,
					회사의 기술적 사유 등 기타 사정에 의하여 유료 서비스를 개시할 수 없는
					경우에는 제9조(회원에 대한 통지)의 방법에 따라 회원에게 사전
					공지합니다.
				</div>
				<div className='margin'>
					2. 회사는 회원의 유료서비스 이용 신청이 있는 경우, 해당 회원에게
					이용신청의 내용을 통지합니다. 회사의 통지를 받은 회원은 의사표시의
					불일치 등이 있는 경우 회사에 정정 또는 수정을 요청하여야 하며, 회사는
					회원의 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한
					경우에는 이 약관의 청약 철회 등에 관한 규정에 따릅니다.
				</div>
				<div className='margin'>
					3. 회사는 다음 각 호와 같은 유료 서비스를 제공하며, 회사의 사정, 기타
					제반 여건에 따라 서비스 내용을 추가하거나 변경할 수 있습니다.{' '}
				</div>
				<div className='margin'>3-1. 자동 결제 여부에 따른 분류</div>
				<div className='margin'>
					3-1-1. 정기 결제형 서비스 유료 회원이 미리 등록한 결제 수단을 통하여
					월 단위로 이용요금이 자동으로 결제되고 이용기간이 자동 갱신되는 서비스
				</div>
				<div className='margin'>
					3-1-2. 기간 만료 형 서비스 이용 가능 기간이 만료되는 경우,
					유료서비스의 이용이 종료되는 서비스
				</div>
				<div className='margin'>3-2. 판매 방식에 따른 분류</div>
				<div className='margin'>
					3-2-1. 회사가 제공하는 유료 서비스를 회원에게 결제 수단을 통해 직접
					제공하는 방식을 말합니다.
				</div>
				<div className='margin'>
					3-2-2. 회사가 제공하는 유료 서비스를 회사 협력 노무사 등 업무 대리인
					또는 대행인이 결제하고 회원에게 직접 제공하는 방식을 말합니다.
				</div>
				<div className='margin'>3-3. 제공 서비스에 따른 분류</div>
				<div className='margin'>
					3-3-1. 유료회원에게 기본으로 제공되는 시스템을 말합니다. 제공되는
					서비스의 종류는 본 약관 제2조 정의 및 제11조에 따릅니다.
				</div>
				<div className='margin'>
					4. 회원이 서비스 신청일로부터 3개월동안 사용 의사를 표명하지 않은 경우
					또는 기타 사유로 필요한 경우 회사는 해당 회원에게 전화, 전자우편 등을
					통해 회원에 대하여 서비스 사용 의사에 대한 확인을 요청할 수 있고,
					회사가 정하는 일정기간 동안 회원의 응답이 없는 경우에 회사는 서비스
					사용을 중지하고 정보를 삭제할 수 있습니다.
				</div>
				<div className='margin'>
					5. 서비스 이용 의사를 표명하지 않은 회원이 서비스를 재사용하고자 하는
					경우 재신청 절차를 거쳐야 합니다.
				</div>
				<br />
				<div className='margin'>제17조. 결제수단</div>
				<div className='margin'>
					회원이 유료서비스의 결제를 위하여 이용할 수 있는 수단은 다음 각 호와
					같습니다.
				</div>
				<div className='margin'>1. 신용카드</div>
				<div className='margin'>2. 실시간 계좌이체</div>
				<div className='margin'>3. CMS 자동이체</div>
				<div className='margin'>4. 휴대폰 결제</div>
				<br />
				<div className='margin'>
					제18조. 요금의 납부방법 및 납기일, 과금의 기준
				</div>
				<div className='margin'>
					1. 본 서비스는 회원이 정보입력과 동시에 본 서비스와 관련된 노동관계
					법령에 따른 형식(근로기준법 제17조 근로조건 명시의무, 제48조제2항
					임금명세서, 제93조 취업규칙 등)이 자동으로 설계되는 특성상 요금은
					선결제가 원칙이고, 1회 결제시 결제일로부터 1개월간 이용 가능하나,
					1개월 미만 이용하면서 계약을 해지(해제는 불가능)하여도 결제 금액은
					서비스 이용기간으로 일자별로 분할 계산되지 아니합니다. 즉, 1회라도
					이용시 1회(1달) 결제 금액을 모두 결제됩니다.
				</div>
				<div className='margin'>
					2. 정기 유료 회원은 유료 결제 시작일부터 역월상 1개월 단위로 매월 최초
					결제일에 자동 결제합니다. 결제 방법 및 정기결제일은 결제수단에 따라 각
					호와 같습니다.{' '}
				</div>
				<div className='margin'>
					2-1. 신용카드: 매월 최초 결제일에 자동 결제
				</div>
				<div className='margin'>
					2-2. 실시간 계좌 이체: 매월 최초 결제일 전까지 회사의 계좌로 서비스
					이용요금 송금
				</div>
				<div className='margin'>
					2-3. CMS 자동이체: 매월 최초 결제일에 자동 이체
				</div>
				<div className='margin'>
					2-4. 휴대폰 결제: 매월 최초 결제일에 자동 이체
				</div>
				<div className='margin'>
					3. 서비스 이용 요금은 회사 웹사이트 요금 페이지의 내용을 따르며, 과금
					기준의 인원 수는 회원이 등록한 직원수를 기준(원칙)으로 하고 과금
					주기는 매월 최초 결제일부터 익월 결제 전일까지로 책정합니다.
				</div>
				<div className='margin'>
					4. 부가유료서비스 이용 요금의 결제는 각 부가유료서비스를 신청할 때
					고시받은 내용과 방법에 따라 진행됩니다.
				</div>
				<br />
				<div className='margin'>제19조. 요금제 변경</div>
				<div className='margin'>
					1. 회원이 이미 구매·가입한 유료서비스는 회원이 변경 신청할 경우 즉시
					반영합니다.
				</div>
				<div className='margin'>
					2. 변경신청 시 새로 적용하는 요금제를 새롭게 사용할 수 있습니다.
				</div>
				<div className='margin'>
					3. 고객이 등록한 재직자 수의 변동으로 인해 상기 제 18조 3항에 명시된
					기준에 따라 요금제의 변경이 필요한 경우, 회사는 변경된 요금제를
					적용합니다.
				</div>
				<br />
				<div className='margin'>
					제20조. 청약 철회 및 서비스 이용 계약의 해제·해지
				</div>
				<div className='margin'>
					1. 유료회원은 해당 유료서비스를 전혀 사용하지 아니하였을 경우에 한하여
					정기결제일로부터 7일 이내에 회사에 결제 취소(청약 철회)를 요청할 수
					있습니다.
				</div>
				<div className='margin'>
					2. 유료회원이 유료서비스에 대하여 전혀 사용하지 아니하였더라도, 청약
					철회 가능한 기간(정기결제일로부터 7일 이내)을 경과하여 청약 철회를
					신청하였을 때 환불은 불가능합니다.
				</div>
				<div className='margin'>
					3. 유료회원이 유료서비스의 해지를 요청하면, 회사는 해지월 익월 최초
					결제일 전일까지 서비스를 제공합니다.
				</div>
				<div className='margin'>
					4. 청약의 철회는 유료회원이 서비스 홈페이지 및 전자우편을 통해 할 수
					있으며, 회사에 의사를 표시하여 회사에 도달될 때 그 효력이 발생하고,
					회사는 유료회원의 의사표시를 수령한 후 지체 없이 이러한 사실을
					유료회원에게 회신합니다.
				</div>
				<div className='margin'>
					5. 회사는 유료회원의 청약 철회, 해지 신청을 확인한 후 환불 금액이 있을
					경우(예, 매월 자동 결제가 아닌 00개월 선결제 등), 원칙적으로
					유료회원의 해당 의사표시를 수령한 날로부터 3영업일 이내에 결제 수단별
					사업자에게 대금의 청구 정지 내지 취소를 요청하고, 유료 회원이 결제한
					동일 결제수단으로 해지 요청월 익월부터 선결제 받는 금액을 환불함을
					원칙으로 합니다. 단, 회사가 사전에 유료회원에게 전자메일, 서비스
					홈페이지로 공지한 경우 및 아래의 각 호의 경우와 같이 개별 결제 수단별
					환불 방법, 환불 가능 기간 등이 차이가 있을 수 있습니다.{' '}
				</div>
				<div className='margin'>
					5-1. 신용카드 등 수납 확인이 필요한 결제수단의 경우에는 수납
					확인일로부터 3영업일 이내
				</div>
				<div className='margin'>
					5-2. 결제수단별 사업자가 회사와의 약정을 통하여 사전에 대금 청구 정지
					내지 결제 취소 가능 기한 등을 미리 정하여 둔 경우로 해당 기한을 지난
					환불의 경우
				</div>
				<div className='margin'>
					5-3. 유료회원이 환불 처리에 필요한 정보 내지 자료를 회사에 즉시
					제공하지 않는 경우(현금 환불 시 신청인의 계좌 및 신분증 사본을
					제출하지 아니하거나, 타인 명의의 계좌를 제공하는 경우 등)
				</div>
				<div className='margin'>
					5-4. 해당 회원의 명시적 의사표시가 있는 경우
				</div>
				<div className='margin'>
					6. 회원이 유료서비스를 선물받거나, 프로모션 등을 통해 무료·무상으로
					취득하는 등 회원이 직접 비용을 지불하지 아니하고 이용하는 유료서비스에
					대하여 회사는 회원에게 유료서비스 결제 대금을 환불할 의무를 부담하지
					않습니다.
				</div>
				<div className='margin'>
					7. 회원이 이 약관에서 정하는 회원의 의무를 위반하였을 경우, 계약을
					해제·해지하거나 서비스 이용 제한, 손해배상 청구 등의 조치를 취할 수
					있으며, 계약 해지 후 환불하여야 할 금액이 있을 경우에는 일정한 범위
					내에서 회원이 부담할 금액을 공제하고 환불합니다. 이 경우 회원은 해당
					회사의 조치에 대하여 회사가 정한 절차에 따라 이의 신청을 할 수 있으며,
					회사는 정당하다고 확인하는 경우 서비스 이용 재개 등을 할 수 있으며,
					회원이 자신의 고의·과실 없었음을 입증한 경우 회사는 서비스를 정지한
					기간만큼 이용 기간을 연장합니다.
				</div>
				<div className='margin'>
					8. 유료회원의 신청 또는 동의에 따라 월 정기결제 중인 유료서비스의
					경우, 해당 회원이 유료서비스의 이용요금을 체납하는 경우 연체가 발생한
					날 자동으로 이용권 해지가 될 수 있으므로, 유료서비스를 유지하고자 하는
					유료 회원은 이용 요금의 체납 또는 결제수단의 연체가 발생하지 않도록
					사전에 조치하여야 합니다.
				</div>
				<div className='margin'>
					9. 유료서비스를 이용 중인 유료 회원이 탈퇴하는 경우 유료 회원의 정보와
					서비스 이용 내용은 관련법령이 정하는 경우를 제외하고는 이 약관에 따라
					처리됩니다.
				</div>
				<div className='margin'>
					10. 부가유료서비스 진행 기간 중도에 서비스를 해지하고자 할 경우 회사는
					유료회원에게 부가유료서비스 진행 잔여 기간 동안 발생할 것이라고
					기대되는 수익의 50%이상 100%미만을 위약금으로 청구할 수 있습니다.
				</div>
				<br />
				<div className='margin'>제21조. 유료서비스 가액 변경</div>
				<div className='margin'>
					회사는 운영상, 기술상의 필요에 따라 공급하는 유료서비스 가액을 변경할
					수 있으며, 제9조의 방법에 따라 유료 회원에게 사전 공지합니다.
				</div>
				<br />
				<div className='margin'>제22조. 과오금</div>
				<div className='margin'>
					1. 회사는 유료서비스 결제와 관련하여 과오금이 발생한 경우 이용대금의
					결제와 동일한 방법으로 과오금 전액을 환불합니다. 다만, 동일한 방법으로
					환불이 불가능할 때는 이를 사전에 고지합니다.
				</div>
				<div className='margin'>
					2. 회사의 귀책사유로 과오금이 발생한 경우 과오금 전액을 환불합니다.
					단, 회원의 귀책사유로 과오금이 발생한 경우, 회사가 과오금을 환불하는데
					소요되는 비용은 합리적인 범위 내에서 회원가 부담하여야 하며, 회사는
					해당 비용을 차감 후 과오금을 환불할 수 있습니다.
				</div>
				<div className='margin'>
					3. 회사는 회원이 과오금을 주장하며 환불을 요구하는 경우, 정당하게
					유료서비스 요금이 부과되었음을 입증하면 환불을 거부할 수 있습니다.
				</div>
			</div>
			<div style={laborContractStyle}>
				<strong>제3장 기타</strong>
				<br />
				<br />
				<div className='margin'>제23조. 최소 사용 환경</div>
				<div className='margin'>
					1. 사용자는 아래 각 호에 해당하는 최소 사용 환경에서 서비스를 이용해야
					합니다.{' '}
				</div>
				<div className='margin'>
					1-1. 운영체제: Mac(OS X Mavericks 이상), Windows(7 이상)
				</div>
				<div className='margin'>
					1-2. 브라우저: Edge(25.1 이상), Chrome(49 이상), Safari(9.0 이상),
					Opera(37 이상), Firefox(43.0 이상)
				</div>
				<div className='margin'>1-3. 쿠키: 모든 쿠키 허용</div>
				<div className='margin'>
					2. 최소 사용 환경을 충족하지 못하는 환경에서 서비스를 이용할 경우,
					이와 발생하는 문제에 대해 회사는 책임지지 않습니다.
				</div>
				<br />
				<div className='margin'>제24조. 회사의 의무</div>
				<div className='margin'>
					1. 회사는 본 약관 및 관련법령에서 금지하는 행위 및 미풍양속에 반하는
					행위를 하지 않으며, 계속적이고 안정적인 서비스의 제공을 위하여 최선을
					다하여 노력합니다.
				</div>
				<div className='margin'>
					2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 신용정보를 포함한
					일체의 개인정보 보호를 위한 보안시스템을 갖추어야 하며
					개인정보처리방침을 공시하고 준수합니다.
				</div>
				<div className='margin'>
					3. 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로
					인정될 경우에는 합리적인 기간 내에 신속하게 처리하여야 합니다. 다만,
					처리에 장기간이 소요되는 경우 회원에게 게시판 또는 전자우편 등을
					통하여 지체 사유를 안내하고 처리과정 및 처리결과를 전달합니다.
				</div>
				<br />
				<div className='margin'>제25조. 회원의 의무</div>
				<div className='margin'>
					1. 회원은 회사에서 제공하는 서비스를 본래의 이용 목적 이외의 용도로
					사용하거나 다음 각 호에 해당하는 행위를 해서는 안됩니다.{' '}
				</div>
				<div className='margin'>
					1-1. 가입신청 또는 정보 변경을 목적으로 회사에 개인정보 등록시 실명이
					아닌 정보 또는 다른 사람의 정보를 사용하거나 허위 사실을 기재하는 행위
				</div>
				<div className='margin'>
					1-2. 타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위, 다른
					회원의 계정 및 비밀번호를 도용, 부정하게 사용하는 행위
				</div>
				<div className='margin'>
					1-3. 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위
				</div>
				<div className='margin'>
					1-4. 회사 및 제3자의 명예를 훼손하거나 업무를 방해하거나 회사 및
					제3자에게 손해를 가하는 행위
				</div>
				<div className='margin'>
					1-5. 회사의 지적재산권, 제3자의 지적재산권, 초상권 등 기타 권리를
					침해하거나 회사의 승인을 받지 않고 다른 회원의 개인정보를 수집, 저장,
					유포, 게시하는 행위
				</div>
				<div className='margin'>
					1-6. 제3자를 기망하여 이득을 취하거나 회사가 제공하는 서비스를
					불건전하게 이용하거나 하여 제3자에게 피해를 주는 행위
				</div>
				<div className='margin'>
					1-7. 회사로부터 특별한 권리를 부여받지 않고 사이트를 변경하거나
					사이트에 다른 프로그램을 추가 또는 삽입하거나 서버를 해킹, 역설계,
					소스코드의 유출 및 변경, 별도의 서버를 구축하거나 웹사이트의 일부분을
					임의로 변경 또는 도용하여 회사를 사칭하는 행위
				</div>
				<div className='margin'>
					1-8. 회사의 직원이나 운영자를 가장, 사칭하거나 또는 타인의 명의를
					도용하여 문서를 등록하거나 메일을 발송하는 행위
				</div>
				<div className='margin'>
					1-9. 회사의 동의 없이 영리, 영업, 광고, 정치활동, 불법선거운동 등을
					목적으로 서비스를 이용하는 행위
				</div>
				<div className='margin'>
					1-10. 기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 행위 및
					법령에 위배되는 행위
				</div>
				<div className='margin'>
					2. 회원은 회사 홈페이지 상의 공지사항 및 이용약관의 수정사항 등을
					확인하고 이를 준수할 의무가 있으며 기타 회사의 업무에 방해되는 행위를
					하여서는 안됩니다.
				</div>
				<div className='margin'>
					3. 회원의 계정에 관한 관리 책임은 회원에게 있으며, 이를 제3자가
					이용하도록 하여서는 안 됩니다.
				</div>
				<div className='margin'>
					4. 회사는 제1항, 제2항 및 다음 각 호의 어느 하나에 해당하는 행위의
					구체적인 유형을 운영 정책에서 정할 수 있으며, 회원은 이를 준수할
					의무가 있습니다.{' '}
				</div>
				<div className='margin'>
					4-1. 회원의 계정명, 비밀번호의 정함에 대한 제한
				</div>
				<div className='margin'>
					4-2. 기타 회원의 서비스 이용에 대한 본질적인 권리를 침해하지 않는 범위
					내에서 회사가 운영상 필요하다고 인정되는 사항
				</div>
				<div className='margin'>
					5. 회원은 회원가입 신청시 기재한 정보가 변경되었을 경우에는, 즉시
					온라인으로 수정을 하여야 하며 이용 신청시 기재한 사항 중 변경 사항을
					수정하지 않아 발생되는 문제의 책임은 회원에게 있습니다.
				</div>
				<div className='margin'>
					{' '}
					<span style={{ color: 'blue' }}>
						6. 본 서비스는 인사급여 관리 프로그램으로 일정 알로리즘을 통해
						근로계약서, 임금대장 등 노동법상 서류가 설계되고 작성되는 부분이
						있으나, 노동법에 부합하는지에 대한 확인은 불가능(공인노무사법 제2조
						제1항 제2호("노동관계법령에 따른 서류의 작성과 확인")에 따라 작성과
						확인이 동시에 이루어지는 서비스는 공인노무사만 가능함.)하므로 본
						서비스를 통해 산출되는 결과물은 법적 효력을 갖는 유권해석(결정,
						판단)이 아님을 명백히 안내해드리며, 입력 결과에 대하여는
						사용자(회원)가 충분한 검토와 확인을 하시고, 노동법은 공인노무사,
						세법은 세무사나 회계사 등 관련 법령에 의해 법률 해석 및 확인에 대한
						적법한 권한을 가진 전문가의 조력과 확인받을 것을 강력히 권고하며, 본
						서비스 이용으로 인한 책임은 회원이 부담함을 안내해 드립니다.
					</span>
				</div>
				<br />
				<div className='margin'>제26조. 손해배상</div>
				<div className='margin'>
					1. 회사가 제공하는 유료 서비스의 하자(본 시스템의 오류 등으로 이용이
					제한되는 경우 외에 기타 서비스의 하자라 함은 근로기준법 제17조에 따른
					구성항목(임금의 구성항목, 계산방법, 지급방법, 소정근로시간, 휴가, 휴일
					등 6개 필수 항목)의 누락, 동법 제48조 제2항에 따른 임금명세서
					구성항목의 누락을 말한다.) 및 제24조의 회사의무를 위반하여 회원에게
					피해가 발생한 경우, 회사는 회원에 대하여 사용하지 못한 기간에 대한
					이용료 상당액을 손해를 배상하여야 합니다.
				</div>
				<div className='margin'>
					2. 회원이 이 약관의 의무를 위반함으로 인하여 회사에 손해를 입힌 경우
					또는 회원이 서비스의 이용과 관련하여 회사에 손해를 입힌 경우 회원은
					회사에 대하여 손해를 배상하여야 합니다.
				</div>
				<div className='margin'>
					3. 회원이 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한
					행위로 회사가 당해 회원 외의 제3자로부터 손해배상청구 또는 소송 등
					각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를
					면책시켜야 하며, 회사가 면책되지 못한 경우 당해 회원는 그로 인하여
					회사에 발생한 모든 손해를 배상할 책임이 있습니다.
				</div>
				<br />
				<div className='margin'>제27조. 책임의 한계</div>
				<div className='margin'>
					1. 회사는 천재지변 또는 국가 정책 등 이에 준하는 불가항력으로 인하여
					서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
				</div>
				<div className='margin'>
					2. 회사는 회원의 귀책사유로 인한 서비스 중지 또는 이용의 장애에
					대하여는 책임을 지지 아니하며, 기간통신사업자가 전기통신서비스를
					중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한
					경우에는 책임이 면제됩니다.
				</div>
				<div className='margin'>
					3. 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도,
					정확성 등의 내용에 관하여는 책임을 지지 않습니다.
				</div>
				<div className='margin'>
					4. 회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래
					등을 한 경우에는 책임이 면제됩니다.
				</div>
				<br />
				<div className='margin'>제28조. 준거법 및 재판관할</div>
				<div className='margin'>
					1. 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
				</div>
				<div className='margin'>
					2. 회사와 회원간 발생한 분쟁에 관한 소송은 민사소송법 상의 회사의
					본사소재지를 관할하는 법원을 관할법원으로 하여 제소합니다.
				</div>
			</div>
			<div style={laborContractStyle}>
				<strong>제4장 부칙</strong>
				<br />
				<div className='margin'>제29조. 시행일</div>
				<div className='margin'>이 약관은 2024년 09월 15일부터 시행합니다.</div>
				<div className='margin'>
					단, 본 약관의 공지 이후 적용일 이전에 가입한 신규회원에 대해서는
					전단의 규정에도 불구하고 회원 가입시부터 본 약관이 적용됩니다.
				</div>
				<br />
			</div>
		</>
	);
}
