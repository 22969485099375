import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Label } from 'semantic-ui-react';
import {
	lowWageUnderWonComment,
	lowWageViolationComment,
	numberToCommaString,
	printNoComment,
	printOKComment,
	specialPermission52Comment,
	under5Comment,
	week52ViolationComment,
	restViolationDashboardComment,
	printForm,
	freeComClient,
} from '../../../../../app/common/util/util';

export default function ClientReqDLCJudge({
	//근로계약서 위 프린트 부분
	selectedClientReq4In,
	isEtcWorker,
}) {
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo, superAdminForFreeTrue } = useSelector(
		(state) => state.companyReducer
	);
	const ceoName = clientInfo?.ceoName
		? clientInfo?.ceoName
		: currentUserProfile?.ceoName;

	const judge52 = selectedClientReq4In?.lawBase?.judge52;
	const restTotalViolation = selectedClientReq4In?.lawBase?.restTotalViolation;
	const judgeLowestWage = selectedClientReq4In?.lawBase?.judgeLowestWage;
	const insufficientWage = selectedClientReq4In?.lawBase?.insufficientWage;
	const numOfWorkers = selectedClientReq4In?.numOfWorkers;
	const over52permission = clientInfo?.over52permission
		? clientInfo?.over52permission
		: currentUserProfile?.over52permission;

	const printFunc = () => {
		printForm();
	};

	function renderComment(condition) {
		return condition ? printOKComment() : printNoComment();
	}

	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const isWorker = currentUserProfile?.isWorker;
	const freeDeleteDisable =
		superAdminForFreeTrue || isWorker
			? false
			: freeComClient(authLevel, ceoName)
			? true
			: false;

	return (
		<>
			{over52permission === '52특례사업장' ? (
				<>
					{isEtcWorker ? (
						<>
							<Button
								type='button'
								color='blue'
								disabled={!judgeLowestWage || freeDeleteDisable}
								onClick={() => printFunc()}>
								{renderComment(judgeLowestWage)}
							</Button>
							{!ceoName ? (
								<Label>최저임금판단은 회사정보등록후 가능</Label>
							) : (
								<>
									<Label
										content={
											judgeLowestWage === true
												? null
												: lowWageViolationComment()
										}
									/>
									{!judgeLowestWage && (
										<Label
											content={
												insufficientWage > 0
													? '월급기준' +
													  numberToCommaString(insufficientWage) +
													  lowWageUnderWonComment()
													: ''
											}
										/>
									)}
								</>
							)}
						</>
					) : (
						<>
							{restTotalViolation !== 'undefined' ? (
								<Button
									type='button'
									color='blue'
									disabled={
										!judgeLowestWage || !restTotalViolation || freeDeleteDisable
									}
									onClick={() => printFunc()}>
									{renderComment(judgeLowestWage && restTotalViolation)}
								</Button>
							) : (
								<Button
									type='button'
									color='blue'
									disabled={!judgeLowestWage || freeDeleteDisable}
									onClick={() => printFunc()}>
									{renderComment(judgeLowestWage)}
								</Button>
							)}
							{numOfWorkers < 5 ? (
								<Label
									content={
										judge52 === true
											? under5Comment()
											: specialPermission52Comment()
									}
								/>
							) : (
								<Label
									content={
										judge52 === true ? null : specialPermission52Comment()
									}
								/>
							)}
							{restTotalViolation !== 'undefined' ? (
								<Label
									content={
										restTotalViolation === true
											? null
											: restViolationDashboardComment()
									}
								/>
							) : null}
							{!ceoName ? (
								<Label>최저임금판단은 회사정보등록후 가능</Label>
							) : (
								<>
									<Label
										content={
											judgeLowestWage === true
												? null
												: lowWageViolationComment()
										}
									/>
									{!judgeLowestWage && (
										<Label
											content={
												insufficientWage > 0
													? '월급기준' +
													  numberToCommaString(insufficientWage) +
													  lowWageUnderWonComment()
													: ''
											}
										/>
									)}
								</>
							)}
						</>
					)}
				</>
			) : (
				<>
					{isEtcWorker ? (
						<>
							<Button
								type='button'
								color='blue'
								disabled={!judgeLowestWage || freeDeleteDisable}
								onClick={() => printFunc()}>
								{renderComment(judgeLowestWage)}
							</Button>
							{!ceoName ? (
								<Label
									content={
										judgeLowestWage === true ? null : lowWageViolationComment()
									}
								/>
							) : (
								<>
									<Label
										content={
											judgeLowestWage === true
												? null
												: lowWageViolationComment()
										}
									/>
									{!judgeLowestWage && (
										<Label
											content={
												insufficientWage > 0
													? '월급기준' +
													  numberToCommaString(insufficientWage) +
													  lowWageUnderWonComment()
													: ''
											}
										/>
									)}
								</>
							)}
						</>
					) : (
						<>
							{restTotalViolation !== 'undefined' ? (
								<Button
									type='button'
									color='blue'
									disabled={
										!judge52 ||
										!judgeLowestWage ||
										!restTotalViolation ||
										freeDeleteDisable
									}
									onClick={() => printFunc()}>
									{renderComment(
										judge52 && judgeLowestWage && restTotalViolation
									)}
								</Button>
							) : (
								<Button
									type='button'
									color='blue'
									disabled={!judge52 || !judgeLowestWage || freeDeleteDisable}
									onClick={() => printFunc()}>
									{renderComment(judge52 && judgeLowestWage)}
								</Button>
							)}
							{numOfWorkers < 5 ? (
								<Label
									content={
										judge52 === true
											? under5Comment()
											: week52ViolationComment()
									}
								/>
							) : (
								<Label
									content={judge52 === true ? null : week52ViolationComment()}
								/>
							)}
							{restTotalViolation !== 'undefined' ? (
								<Label
									content={
										restTotalViolation === true
											? null
											: restViolationDashboardComment()
									}
								/>
							) : null}
							{!ceoName ? (
								<Label
									content={
										judgeLowestWage === true ? null : lowWageViolationComment()
									}
								/>
							) : (
								<>
									<Label
										content={
											judgeLowestWage === true
												? null
												: lowWageViolationComment()
										}
									/>
									{!judgeLowestWage && (
										<Label
											content={
												insufficientWage > 0
													? '월급기준' +
													  numberToCommaString(insufficientWage) +
													  lowWageUnderWonComment()
													: ''
											}
										/>
									)}
								</>
							)}
						</>
					)}
				</>
			)}
		</>
	);
}
