import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function PaydacBSC() {
	const opts = {
		// height: '300%',
		width: '100%',
		playVars: {
			autoplay: 1,
		},
	};

	return (
		<ModalWrapper size='large' header='SafeHR BSC관점 역량모델링 관리'>
			<YouTube videoId='EKvSNZZuOzs' opts={opts} />
		</ModalWrapper>
	);
}
