import React from 'react';

export default function ClientDashNewFunction({
	setOpenWhatsNew,
	openWhatsNew,
}) {
	return (
		<div
			onClick={() => setOpenWhatsNew(!openWhatsNew)}
			style={{ cursor: 'pointer' }}>
			<h3 style={{ marginBottom: '20px', color: 'blue' }}>
				* 24년 09월 15일 : SafeHR 서비스 시작합니다.
			</h3>
			<button
				style={{
					fontSize: '15px',
					color: 'black',
					height: '30px',
					width: '200px',
					background: 'white',
				}}
				onClick={() => setOpenWhatsNew(!openWhatsNew)}>
				내용 닫기
			</button>
			<hr />
		</div>
	);
}
