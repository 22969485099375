import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function SeeWorkerLaborContract() {
	const opts = {
		// height: '300%',
		width: '100%',
		playVars: {
			autoplay: 1,
		},
	};

	return (
		<ModalWrapper
			size='large'
			header='SafeHR 감단직 근로계약서 기능 설명 동영상'>
			<YouTube videoId='f81dtWi-64Y' opts={opts} />
		</ModalWrapper>
	);
}
