import React, { useEffect } from 'react'
import BotDashboard from './BotDashboard'
import { connectWithRestfulApiServer } from '../chatbotSocketConnection/socketConn'
import { useSelector } from 'react-redux';

function ChatBotGpt() {

  const {currentUserProfile} = useSelector(state => state.profile)

  useEffect(() => {
    if (currentUserProfile && currentUserProfile.id) {
      connectWithRestfulApiServer(currentUserProfile);
    }
  }, [currentUserProfile]);

  return (
    <div>
      <BotDashboard />
    </div>
  )
}

export default ChatBotGpt
