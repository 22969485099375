import React from 'react';
import MyNumberInput from '../../../../../app/common/form/MyNumberInput';
import MyTextInput from '../../../../../app/common/form/MyTextInput';
// import { useDispatch } from 'react-redux';
// import { openModal } from '../../../../../app/common/modals/modalReducer';
// import { Icon, Label } from 'semantic-ui-react';

export default function CrwfWorkerBasicInfoMonthWageAver({
	selectedClientReq4In,
}) {
	// const dispatch = useDispatch();

	// function workerRegStep3_3YTOpen() {
	//   dispatch(openModal({modalType:'WorkerRegStep3_3'}))
	// }

	function openAverWageTFHandler() {
		alert(
			'회원의 수기입력 특성상 비과세 한도액 설정이 불가능하여 입력된 금액 전체가 비과세로 계산되는점 유의하세요.'
		);
	}

	return (
		<>
			{/* <div style={{display: 'flex', alignItems : 'center', alignContent : 'flex-end', width : '100%', marginBottom : "30px", marginTop : "30px", fontSize: '17px'}}>
        <span style={{color : 'red'}}>* </span><span style={{color : 'blue'}}><span style={{color : 'teal', fontSize: '16px'}}>일정 조건이 충족되면 지급되는 월정액의 </span>
        <span style={{color : 'teal'}}>평균임금</span>항목 있는 경우<span style={{color : 'red'}}>(주의 : 월급 총액에 포함된 월정액 평균임금만 입력)</span><span style={{fontSize : '8px'}}>(설명은 오른쪽 동영상을 참조하세요.)</span></span>
        <span style={{position: "absolute", right: 0, marginRight:'10px', cursor:'pointer'}}><Label style={{color:'red'}} onClick={() => workerRegStep3_3YTOpen()}><Icon size='big' name='youtube' />평균임금설정</Label></span>
      </div> */}
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div
					style={{
						width: '100%',
						marginBottom: '10px',
						marginTop: '50px',
						fontSize: '13px',
					}}>
					● <span style={{ color: 'teal' }}>비과세</span> 평균임금 설계시
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(8-1-1) 평균임금(비과세) 금액'
						name='averageWageTF.irregularAWage1'
						autoComplete='off'
						onClick={() => openAverWageTFHandler()}
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-1-2) 소정근로 외 조건 달성시 지급되는 평균임금(비과세) 조건'
						name='averageWageTF.irregularAWCondition1'
						placeholder='예) 소정근로 80% 이상 출근시 지급, 전직원 지각이 없을 경우 지급, 전직원 개인 목표달성시 지급 등'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-1-3) 평균임금(비과세) 명칭'
						name='averageWageTF.irregularAWName1'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(8-2-1) 평균임금(비과세) 금액'
						name='averageWageTF.irregularAWage2'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-2-2) 소정근로 외 조건 달성시 지급되는 평균임금(비과세) 조건'
						name='averageWageTF.irregularAWCondition2'
						placeholder='예) 소정근로 80% 이상 출근시 지급, 전직원 지각이 없을 경우 지급, 전직원 개인 목표달성시 지급 등'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-2-3) 평균임금(비과세) 명칭'
						name='averageWageTF.irregularAWName2'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(8-3-1) 평균임금(비과세) 금액'
						name='averageWageTF.irregularAWage3'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-3-2) 소정근로 외 조건 달성시 지급되는 평균임금(비과세) 조건'
						name='averageWageTF.irregularAWCondition3'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-3-3) 평균임금(비과세) 명칭'
						name='averageWageTF.irregularAWName3'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(8-4-1) 평균임금 합계금액'
						name='averageWageTF.irregularAWage4'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-4-2) 소정근로 외 조건 달성시 지급되는 평균임금(비과세) 조건'
						name='averageWageTF.irregularAWCondition4'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-4-3) 평균임금(비과세) 명칭(들)'
						name='averageWageTF.irregularAWName4'
						autoComplete='off'
					/>
				</div>
				<div
					style={{
						width: '100%',
						marginBottom: '10px',
						marginTop: '50px',
						fontSize: '13px',
					}}>
					● <span style={{ color: 'teal', width: '99.5%' }}>기타 평균임금</span>{' '}
					있는 경우
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(8-1-1) 월정액 평균임금 금액(원)'
						name='averageWage.irregularAWage1'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-1-2) 소정근로 외 조건 달성시 지급되는 평균임금 조건'
						name='averageWage.irregularAWCondition1'
						placeholder='예) 소정근로 80% 이상 출근시 지급, 전직원 지각이 없을 경우 지급, 전직원 개인 목표달성시 지급 등'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-1-3) 평균임금 명칭'
						name='averageWage.irregularAWName1'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(8-2-1) 월정액 평균임금 금액(원)'
						name='averageWage.irregularAWage2'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-2-2) 소정근로 외 조건 달성시 지급되는 평균임금(2) 조건'
						name='averageWage.irregularAWCondition2'
						placeholder='예) 소정근로 80% 이상 출근시 지급, 전직원 지각이 없을 경우 지급, 전직원 개인 목표달성시 지급 등'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-2-3) 평균임금 명칭'
						name='averageWage.irregularAWName2'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(8-3-1) 월정액 평균임금 금액(원)'
						name='averageWage.irregularAWage3'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-3-2) 소정근로 외 조건 달성시 지급되는 평균임금 조건'
						name='averageWage.irregularAWCondition3'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-3-3) 평균임금 명칭'
						name='averageWage.irregularAWName3'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(8-4-1) 월정액 평균임금 금액(원)'
						name='averageWage.irregularAWage4'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-4-2) 소정근로 외 조건 달성시 지급되는 평균임금 조건'
						name='averageWage.irregularAWCondition4'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-4-3) 평균임금 명칭'
						name='averageWage.irregularAWName4'
						autoComplete='off'
					/>
				</div>
			</div>
		</>
	);
}
