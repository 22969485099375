import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function PayPaperNetCal() {
	const opts = {
		// height: '300%',
		width: '100%',
		playVars: {
			autoplay: 1,
		},
	};

	return (
		<ModalWrapper
			size='large'
			header='SafeHR Net임금계산기, 세후실수령액 계산기'>
			<YouTube videoId='l6YZjQp_pwk' opts={opts} />
		</ModalWrapper>
	);
}
