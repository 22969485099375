import React from 'react';
import { Table } from 'semantic-ui-react';

function ClientWageTableTop2TimeDeduct({
	payPaperMinusTimeYTOpen,
	worker,
	payPaperAddTimeYTOpen,
	centerStyle,
	numOfWorkers,
	isExceptTimeLawWorker,
	weekLBTime,
	monthOWTHoli,
	monthNWTHoli,
	annualLeavesIncludeMonthWageTime,
	decryptoData,
	monthLBTime,
	hourOrdinaryWage,
	workerEnteredDate,
	workerRetiredDate,
	startToEndDate,
	payDay,
}) {
	return (
		<>
			<Table.Row>
				<Table.Cell textAlign='left' colSpan='9'>
					<span
						style={{
							fontSize: '1.2rem',
							color: 'darkcyan',
							fontWeight: 'bold',
						}}>{`2. ${worker}의 추가근무시간 및 공제내역(시간기준)`}</span>
					<span
						style={{
							position: 'absolute',
							right: 0,
							marginRight: '30px',
							cursor: 'pointer',
						}}>
						{/* <span
							style={{ color: 'red' }}
							onClick={() => payPaperMinusTimeYTOpen()}>
							<Icon size='big' name='youtube' />
							결근, 주휴, 지각 등 공제 계산방법 설명
						</span>
					</span>
					<span
						style={{
							position: 'absolute',
							right: 0,
							marginRight: '300px',
							cursor: 'pointer',
						}}>
						<span
							style={{ color: 'red' }}
							onClick={() => payPaperAddTimeYTOpen()}>
							<Icon size='big' name='youtube' />
							연장/야간/휴일 근로 계산방법 설명
						</span> */}
					</span>
				</Table.Cell>
			</Table.Row>

			{numOfWorkers >= 5 ? (
				<>
					{isExceptTimeLawWorker ? (
						<Table.Row>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가연장
								<br />
								시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가야간
								<br />
								시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								<span style={{ color: 'red' }}>
									결근 <br />
									공제일수
								</span>
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								<span style={{ color: 'red' }}>
									지각, 조퇴 등<br />
									공제시간
								</span>
							</Table.Cell>
							{weekLBTime <= 15 ? null : (
								<Table.Cell
									textAlign='center'
									className='gubun'
									style={centerStyle}>
									<span style={{ color: 'red' }}>사용연차수</span>
								</Table.Cell>
							)}
							<Table.Cell textAlign='center'></Table.Cell>
							<Table.Cell textAlign='center'></Table.Cell>
							<Table.Cell textAlign='center'></Table.Cell>
							<Table.Cell textAlign='center'></Table.Cell>
						</Table.Row>
					) : (
						<Table.Row>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가연장
								<br />
								시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가야간
								<br />
								시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가휴일
								<br />
								기본시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가휴일
								<br />
								초과시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가휴일
								<br />
								야간시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								<span style={{ color: 'red' }}>
									결근 <br />
									공제일수
								</span>
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								<span style={{ color: 'red' }}>
									주휴 <br />
									공제일수
								</span>
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								<span style={{ color: 'red' }}>
									지각, 조퇴 등<br />
									공제시간
								</span>
							</Table.Cell>
							{weekLBTime <= 15 ? null : (
								<Table.Cell
									textAlign='center'
									className='gubun'
									style={centerStyle}>
									<span style={{ color: 'red' }}>사용연차수</span>
								</Table.Cell>
							)}
						</Table.Row>
					)}
				</>
			) : (
				<>
					{isExceptTimeLawWorker ? (
						<Table.Row>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가초과
								<br />
								시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가야간
								<br />
								시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								<span style={{ color: 'red' }}>
									결근 <br />
									공제일수
								</span>
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								<span style={{ color: 'red' }}>
									지각, 조퇴 등<br />
									공제시간
								</span>
							</Table.Cell>
							<Table.Cell textAlign='center'></Table.Cell>
							<Table.Cell textAlign='center'></Table.Cell>
							<Table.Cell textAlign='center'></Table.Cell>
							<Table.Cell textAlign='center'></Table.Cell>
							<Table.Cell textAlign='center'></Table.Cell>
						</Table.Row>
					) : (
						<Table.Row>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가초과
								<br />
								시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가야간
								<br />
								시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가휴일
								<br />
								기본시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가휴일
								<br />
								초과시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								추가휴일
								<br />
								야간시간수
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								<span style={{ color: 'red' }}>
									결근 <br />
									공제일수
								</span>
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								<span style={{ color: 'red' }}>
									주휴 <br />
									공제일수
								</span>
							</Table.Cell>
							<Table.Cell
								textAlign='center'
								className='gubun'
								style={centerStyle}>
								<span style={{ color: 'red' }}>
									지각,조퇴 등<br />
									공제시간
								</span>
							</Table.Cell>
							<Table.Cell textAlign='center'></Table.Cell>
						</Table.Row>
					)}
				</>
			)}
		</>
	);
}

export default ClientWageTableTop2TimeDeduct;
