import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function PaydacAnnualleaves() {
	const opts = {
		// height: '300%',
		width: '100%',
		playVars: {
			autoplay: 1,
		},
	};

	return (
		<ModalWrapper size='large' header='SafeHR 연차(법정, 회계)자동 관리'>
			<YouTube videoId='X4IYVgg5gX4' opts={opts} />
		</ModalWrapper>
	);
}
