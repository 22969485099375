import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function WholeWagesLaborContract() {
	const opts = {
		// height: '300%',
		width: '100%',
		playVars: {
			autoplay: 1,
		},
	};

	return (
		<ModalWrapper
			size='large'
			header='SafeHR 포괄역산 근로계약서 기능 설명 동영상'>
			<YouTube videoId='D8ejVsMfcQQ' opts={opts} />
		</ModalWrapper>
	);
}
