import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Header, Segment, Button, Divider } from 'semantic-ui-react';
import { freeComClient } from '../../app/common/util/util';
import { dataFromSnapshotIdDate } from '../../app/firestore/firestoreService';
import { getWorkerUserProfile } from '../../app/firestore/firestoreService4In';
import { listenToWorkerUsers } from '../profiles/profileActions';

export default function HomePage({ history }) {
	const dispatch = useDispatch();
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const ceoName = clientInfo?.ceoName
		? clientInfo?.ceoName
		: currentUserProfile?.ceoName;

	const isWorker = currentUserProfile?.isWorker;
	const agentUid = currentUserProfile?.agentUid;
	const agentName = currentUserProfile?.agentName;
	const agentWorkerPhone = currentUserProfile?.agentWorkerPhone;
	const agentWorkerName = currentUserProfile?.agentWorkerName;

	useEffect(() => {
		if (currentUserProfile) {
			let isMounted = true;
			const unSubscribe = getWorkerUserProfile({
				next: (snapshot) => {
					if (isMounted) {
						dispatch(
							listenToWorkerUsers(
								snapshot.docs.map((docSnapshot) =>
									dataFromSnapshotIdDate(docSnapshot)
								)
							)
						);
					}
				},
			});
			return () => {
				unSubscribe();
				isMounted = false;
			};
		}
	}, [dispatch, currentUserProfile]);

	return (
		<Segment inverted textAlign='center' vertical className='masthead'>
			<Container>
				<div className='logo-container'>
					<img
						src='SafeHR_shield_with_transparent_background.png'
						alt='SafeHR_favicon_with_text'
						className='logo-top-left'
					/>
				</div>
				{isWorker ? (
					<>
						{currentUserProfile?.agentName &&
							currentUserProfile?.agentUid &&
							currentUserProfile?.agentType === '제약영업' && (
								<Header
									as='h2'
									inverted
									style={{
										marginTop: '230px',
										marginBottom: '-200px',
										textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
									}}>
									{currentUserProfile?.agentName}의
								</Header>
							)}
					</>
				) : (
					<></>
				)}
				<Header
					as='h1'
					className={`h1responsive-text`}
					inverted
					style={{
						marginTop: '200px',
						textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
					}}>
					SafeHR
				</Header>
				<Header
					as='h2'
					className={`h2responsive-text`}
					inverted
					style={{
						textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
					}}>
					세상에 없던 인사관리 시스템
				</Header>
				<Divider className='gray-divider' />
				{freeComClient(authLevel, ceoName) && !isWorker ? (
					<Button
						color='black'
						onClick={() => history.push('/pricePolicyPaper')}
						size='massive'
						inverted>
						시작하기
					</Button>
				) : (
					<Button
						color='black'
						onClick={() => history.push('/clientReqs4In')}
						size='massive'
						inverted
						className='custom-large-button'>
						시작하기
					</Button>
				)}
				<br />
				<br />
				<br />
				<br />
				{agentName && agentUid && (
					<div
						className={`divresponsive-text`}
						style={{
							textAlign: 'center',
							fontSize: '1.5rem',

							margin: '10px 0',
							padding: '5px',
						}}>
						<span>
							Sponsored By {agentName} - 담당 : {agentWorkerName}, 비과세,
							근로소득세, 연말정산 등 세법문의 : {agentWorkerPhone}
						</span>
					</div>
				)}
				<div
					className={'div2responsive-text'}
					style={{
						textAlign: 'center',
						fontSize: '2rem',
						margin: '10px 0',
						padding: '5px',
					}}>
					(주) 카이소프트 ||
					<Link
						to='/clientUseContractPaper'
						style={{ color: 'inherit', textDecoration: 'inherit' }}>
						이용약관
					</Link>
					||
					<Link
						to='/clientPrivateProtectPaper'
						style={{ color: 'inherit', textDecoration: 'inherit' }}>
						개인정보처리방침
					</Link>
				</div>
				<div
					className={`divresponsive-text`}
					style={{
						textAlign: 'center',
						fontSize: '1.5rem',
						margin: '10px 0',
						padding: '5px',
					}}>
					세종특별시 갈매로 351 에비뉴힐 B 4115호
				</div>
				<div
					className={`divresponsive-text`}
					style={{
						textAlign: 'center',
						fontSize: '1.5rem',
						margin: '10px 0',
						padding: '5px',
					}}>
					사업자번호 : 108-81-80248 통신판매업신고증 : 2011-서울강남-00926
				</div>
				<div
					className={`divresponsive-text`}
					style={{
						textAlign: 'center',
						fontSize: '1.5rem',
						margin: '10px 0',
						padding: '5px',
					}}>
					대표자명 : 김경모 대표번호 : 02-521-9467 팩스번호 : 02-6442-9329
					이메일 : paydacbiz@google.com
				</div>
				<div
					className={`divresponsive-text`}
					style={{
						textAlign: 'center',
						fontSize: '1.5rem',
						margin: '10px 0',
						padding: '5px',
					}}>
					(C) (주)카이소프트 ALL RIGHTS RESERVED
				</div>
				<hr />
			</Container>
		</Segment>
	);
}
