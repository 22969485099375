import React from 'react'
import { Table } from 'semantic-ui-react'
import { roundUp } from '../../../../app/common/util/util';

export default function HrManage({
  selectedClientReq4In,
  currentUserProfile, 
  clientInfo,
  centerStyle, 
  values,
  handelInputChange,
}) {
  const authLevel = clientInfo?.authLevel? clientInfo?.authLevel : currentUserProfile?.authLevel;

  const companyPersonalEvaluation = clientInfo?.personalEvaluation ? clientInfo?.personalEvaluation : currentUserProfile?.personalEvaluation;
  const worker = selectedClientReq4In?.worker?.name;

  const openWorkerPersonalEvaluation1 = companyPersonalEvaluation === 'BSC 기반 인사고과 기능 사용' ? true : false;
  const openWorkerPersonalEvaluation2 = authLevel > 30 ? true : false;
  const openWorkerPersonalEvaluation3 = selectedClientReq4In?.workerPersonalEvaluation === `${worker} 직원 BSC 기반 인사고과 기능 사용 안함` ? false : true;
  const openWorkerPersonalEvaluation = openWorkerPersonalEvaluation1 && openWorkerPersonalEvaluation2 && openWorkerPersonalEvaluation3;

  function personnalEveSum() {
    const jobImproveProposal = +values.jobImproveProposal[0] || 0;
    const qualityOfWork = +values.qualityOfWork[0] || 0;
    const amounOfWork = +values.amounOfWork[0] || 0;
    const supportColleague = +values.supportColleague[0] || 0;
    const attenanceManage = +values.attenanceManage[0] || 0;
    const supportCumstomer = +values.supportCumstomer[0] || 0;
    const selfStudy = +values.selfStudy[0] || 0;
    const reportAttitude = +values.reportAttitude[0] || 0;
    const result = jobImproveProposal + qualityOfWork + amounOfWork + supportColleague + attenanceManage + supportCumstomer + selfStudy + reportAttitude
    return result
  }

  function personnalEveRate () {
    const result = personnalEveSum()/36*100 ;
    return result
  }

  const personnalEveHighGrade = 5;
  const personnalEveMiddleGrade = 3;
  const personnalEveLowGrade = 1;
  const personnalEvePanaltyGrade = -1;

  return (
    <>
      {openWorkerPersonalEvaluation &&
        <>
          <Table.Row>
            <Table.Cell colSpan='9' ><span style={{fontSize: '1.2rem', color:'darkcyan', fontWeight:'bold'}}>{`1-1. ${worker}의 BSC 기반 인사고과`}</span></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>업무개선/제안</Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>업무자립도</Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>업무수행률</Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>동료업무지원<br />(선후배 포함) </Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>근태현황</Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>고객/동료지향</Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>자기개발</Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>커뮤니케이션</Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>고성과자 대비</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>
              <select style={{width: "98%"}} name='jobImproveProposal' form='myForm' value={values.jobImproveProposal} onChange={(e)=> handelInputChange(e)}>
                <option value={"0, 제안/개선 없음"}>제안/개선 없음</option>
                <option value={`${personnalEveLowGrade}, 본인 단독 제안`}>본인 단독 제안</option>
                <option value={`${personnalEveMiddleGrade}, '팀과 공유한 제안`}>팀과 공유한 제안</option>
                <option value={`${personnalEveHighGrade}, 업무개선 실행`}>업무개선 실행</option>
              </select>
            </Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>
              <select style={{width: "98%"}} name='qualityOfWork' form='myForm' value={values.qualityOfWork} onChange={(e)=> handelInputChange(e)}>
                <option value={`${personnalEvePanaltyGrade}, "미이행 1건 이상`}>미이행 1건 이상</option>
                <option value={`${personnalEveLowGrade}, 지시업무만 수행`}>지시업무만 수행</option>
                <option value={`${personnalEveMiddleGrade}, 자발적 업무 수행`}>자발적 업무도 수행</option>
                <option value={`${personnalEveHighGrade}, 타의 귀감이 됨`}>타의 귀감이 됨</option>
              </select>
            </Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>
              <select style={{width: "98%"}} name='amounOfWork' form='myForm' value={values.amounOfWork} onChange={(e)=> handelInputChange(e)}>
                <option value={`${personnalEvePanaltyGrade}, 수행률 100% 미만`}>수행률 99% 이하</option>
                <option value={`${personnalEveLowGrade}, 지시업무 100% 수행`}>지시업무 100% 수행</option>
                <option value={`${personnalEveMiddleGrade}, 지시 + 자발적 업무 100%`}>지시 + 자발적 업무 100%</option>
                <option value={`${personnalEveHighGrade}, 타의 귀감이 됨`}>타의 귀감이 됨</option>
              </select>
            </Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>
              <select style={{width: "98%"}} name='supportColleague' form='myForm' value={values.supportColleague} onChange={(e)=> handelInputChange(e)}>
                <option value={"0, 지원 없음"}>지원 없음.</option>
                <option value={`${personnalEveMiddleGrade}, 지원 1건`}>지원 1건</option>
                <option value={`${personnalEveHighGrade}, 지원 2건이상`}>지원 2건이상</option>
              </select>
            </Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>
              <select style={{width: "98%"}} name='attenanceManage' form='myForm' value={values.attenanceManage} onChange={(e)=> handelInputChange(e)}>
                <option value={`${personnalEvePanaltyGrade}, 지각 등 있음`}>지각 등 있음</option>
                <option value={`${personnalEveLowGrade}, 지각 등 없음`}>지각 등 없음</option>
              </select>
            </Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>                   
              <select style={{width: "98%"}} name='supportCumstomer' form='myForm' value={values.supportCumstomer} onChange={(e)=> handelInputChange(e)}>
                <option value={`${personnalEvePanaltyGrade}, 불평 있음`}>불평 있음</option>
                <option value={"0, 칭찬/불만 없음"}>칭찬/불만 없음</option>
                <option value={`${personnalEveMiddleGrade}, 칭찬 1건`}>칭찬 1건</option>
                <option value={`${personnalEveHighGrade}, 칭찬 2건이상`}>칭찬 2건이상</option>
              </select>
            </Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>
              <select style={{width: "98%"}} name='selfStudy' form='myForm' value={values.selfStudy} onChange={(e)=> handelInputChange(e)}>
                <option value={"0, 자기개발없음"}>자기개발없음</option>
                <option value={`${personnalEveMiddleGrade}, 개인학습`}>개인학습</option>
                <option value={`${personnalEveHighGrade}, 학원/모임`}>학원/모임</option>
              </select>
            </Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>
              <select style={{width: "98%"}} name='reportAttitude' form='myForm' value={values.reportAttitude} onChange={(e)=> handelInputChange(e)}>
                <option value={"0, 공유안함"}>공유안함</option>
                <option value={`${personnalEveLowGrade}, 지시사항만 공유`}>지시사항만 공유</option>
                <option value={`${personnalEveMiddleGrade}, 자발적 업무 공유`}>자발적 업무 공유</option>
                <option value={`${personnalEveHighGrade}, 타의 귀감이 됨`}>타의 귀감이 됨</option>
              </select>
            </Table.Cell>
            <Table.Cell textAlign='center' className='gubun' style={centerStyle}>36점 중 {personnalEveSum()}점 <br /> {roundUp(personnalEveRate())}%</Table.Cell>
          </Table.Row>
          {values.jobImproveProposal ?  
            <Table.Row>
              <Table.Cell colSpan='2'>
                <label>업무개선제안 Comments</label>
              </Table.Cell>
              <Table.Cell textAlign='center' colSpan='7' >
                <input
                  type='text'
                  style={{width: "98%"}} 
                  name='jobImproveProposalComments' 
                  form='myForm' 
                  value={values.jobImproveProposalComments} 
                  onChange={(e)=> handelInputChange(e)}
                  placeholder='구체적인 내용를 6하원칙에 따라 기록하세요.(직원에게 전달되는 피드백용입니다.)'
                />
              </Table.Cell>
            </Table.Row>
            : null
          }
          {values.qualityOfWork ?  
            <Table.Row>
              <Table.Cell colSpan='2'>
                <label>업무자립도 Comments</label>
              </Table.Cell>
              <Table.Cell textAlign='center' colSpan='7' >
                <input
                  type='text'
                  style={{width: "98%"}} 
                  name='qualityOfWorkComments' 
                  form='myForm' 
                  value={values.qualityOfWorkComments} 
                  onChange={(e)=> handelInputChange(e)}
                  placeholder='구체적인 내용를 6하원칙에 따라 기록하세요.(직원에게 전달되는 피드백용입니다.)'
                />
              </Table.Cell>
            </Table.Row>
            : null
          }
          {values.amounOfWork ?  
            <Table.Row>
              <Table.Cell colSpan='2'>
                <label>업무수행률 Comments</label>
              </Table.Cell>
              <Table.Cell textAlign='center' colSpan='7' >
                <input
                  type='text'
                  style={{width: "98%"}} 
                  name='amounOfWorkComments' 
                  form='myForm' 
                  value={values.amounOfWorkComments} 
                  onChange={(e)=> handelInputChange(e)}
                  placeholder='구체적인 내용를 6하원칙에 따라 기록하세요.(직원에게 전달되는 피드백용입니다.)'
                />
              </Table.Cell>
            </Table.Row>
            : null
          }
          {values.supportColleague ?  
            <Table.Row>
              <Table.Cell colSpan='2'>
                <label>동료업무지원 Comments</label>
              </Table.Cell>
              <Table.Cell textAlign='center' colSpan='7' >
                <input
                  type='text'
                  style={{width: "98%"}} 
                  name='supportColleagueComments' 
                  form='myForm' 
                  value={values.supportColleagueComments} 
                  onChange={(e)=> handelInputChange(e)}
                  placeholder='구체적인 내용를 6하원칙에 따라 기록하세요.(직원에게 전달되는 피드백용입니다.)'
                />
              </Table.Cell>
            </Table.Row>
            : null
          }
          {values.attenanceManage ?  
            <Table.Row>
              <Table.Cell colSpan='2'>
                <label>근태현황 Comments</label>
              </Table.Cell>
              <Table.Cell textAlign='center' colSpan='7' >
                <input
                  type='text'
                  style={{width: "98%"}} 
                  name='attenanceManageComments' 
                  form='myForm' 
                  value={values.attenanceManageComments} 
                  onChange={(e)=> handelInputChange(e)}
                  placeholder='구체적인 내용를 6하원칙에 따라 기록하세요.(직원에게 전달되는 피드백용입니다.)'
                />
              </Table.Cell>
            </Table.Row>
            : null
          }
          {values.supportCumstomer ?  
            <Table.Row>
              <Table.Cell colSpan='2'>
                <label>고객/동료지향 Comments</label>
              </Table.Cell>
              <Table.Cell textAlign='center' colSpan='7' >
                <input
                  type='text'
                  style={{width: "98%"}} 
                  name='supportCumstomerComments' 
                  form='myForm' 
                  value={values.supportCumstomerComments} 
                  onChange={(e)=> handelInputChange(e)}
                  placeholder='구체적인 내용를 6하원칙에 따라 기록하세요.(직원에게 전달되는 피드백용입니다.)'
                />
              </Table.Cell>
            </Table.Row>
            : null
          }
          {values.selfStudy ?  
            <Table.Row>
              <Table.Cell colSpan='2'>
                <label>자기개발 Comments</label>
              </Table.Cell>
              <Table.Cell textAlign='center' colSpan='7' >
                <input
                  type='text'
                  style={{width: "98%"}} 
                  name='selfStudyComments' 
                  form='myForm' 
                  value={values.selfStudyComments} 
                  onChange={(e)=> handelInputChange(e)}
                  placeholder='구체적인 내용를 6하원칙에 따라 기록하세요.(직원에게 전달되는 피드백용입니다.)'
                />
              </Table.Cell>
            </Table.Row>
            : null
          }
          {values.reportAttitude ?  
            <Table.Row>
              <Table.Cell colSpan='2'>
                <label>커뮤니케이션 Comments</label>
              </Table.Cell>
              <Table.Cell textAlign='center' colSpan='7' >
                <input
                  type='text'
                  style={{width: "98%"}} 
                  name='reportAttitudeComments' 
                  form='myForm' 
                  value={values.reportAttitudeComments} 
                  onChange={(e)=> handelInputChange(e)}
                  placeholder='구체적인 내용를 6하원칙에 따라 기록하세요.(직원에게 전달되는 피드백용입니다.)'
                />
              </Table.Cell>
            </Table.Row>
            : null
          }
          <Table.Row>
            <Table.Cell textAlign='center' colSpan='9' >{` `}</Table.Cell>
          </Table.Row>
        </>
      }
    </>
  )
}
