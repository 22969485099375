import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function WorkerRegStep2() {
	const opts = {
		// height: '300%',
		width: '100%',
		playVars: {
			autoplay: 1,
		},
	};

	return (
		<ModalWrapper
			size='large'
			header='SafeHR 직원 등록 요일별 근무시간, 휴일, 휴게 설정'>
			<YouTube videoId='z9jSApGVOvU' opts={opts} />
		</ModalWrapper>
	);
}
