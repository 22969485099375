import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Loader } from 'semantic-ui-react';

import ClientDashNewFunction from './ClientDashNewFunction';
import useScrollRestoration from '../../../../app/hooks/useScrollRestoration';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function ClientReqDashboard4InInfo() {
	const { saveScrollPosition, restoreScrollPosition } = useScrollRestoration();
	const [openWhatsNew, setOpenWhatsNew] = useState(true);

	const { loading } = useSelector((state) => state.async);
	const history = useHistory();
	async function goBack() {
		history.goBack();
	}

	useEffect(() => {
		restoreScrollPosition();

		return () => {
			saveScrollPosition();
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{/* 약관변경 안내 부분으로 삭제하지 말것
        <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', cursor: "pointer"}} onClick={() => setOpenChangeClientUseContaract(!openChangeClientUseContract)}>
        <span className="material-icons" style={{ fontSize: "25px" }}>notification_important</span>
        <span style={{ fontSize: '23px', color: 'darkBlue' }}>2022년12월12일자 변경 약관 안내(12월5일부터 7일간 공지예정).</span>
      </div>
      {openChangeClientUseContract &&
        <div onClick={() => setOpenChangeClientUseContaract(!openChangeClientUseContract)}>
          <h4 style={{marginBottom: '-20px'}}>적용일자 : 2022년 12월 12일</h4>
          <h4 style={{marginBottom: '-20px'}}>공지시작일자 : 2022년 11월 23일</h4>
          <h4 style={{marginBottom: '-20px'}}><span style={{color: 'blue'}}>개정내용(변경전)</span>            
          <div className='margin' >1-1. "기본형(Basic) 서비스" 라 함은 모바일 및 PC 온라인 상으로 근로계약서, 취업규칙, 인사 정보, 채용 및 고용지원금 관리 등의 인사노무 업무를 수행할 수 있도록 회사가 제공하는 종합 인사노무 관리 전산(공인노무사법 제2조 제2항의 노동관계 법령에 따른 서류의 확인은 제외)만을 제공하는 서비스를 말합니다.</div>
            <div className='margin' >1-2. "고급형(Premium) 서비스" 라 함은 기본형 서비스에 추가하여 회사의 간략한 유선(회당 10분이하) 노동법 노무자문 서비스(공인노무사법 제3조 노동관계법령과 노무관리에 관한 상담, 지도)외 기본형에서 제공하지 않는 정형화된 전산 기능이 추가된 서비스를 말합니다.</div>
            <div className='margin' >1-3. "맞춤형(Customized) 서비스" 라 함은 고급형 서비스에 추가하여 회원의 인사노무 특성이 반영된 최고급형 서비스를 말합니다.</div>
            <div className='margin' >2-1. "기본형 유료 서비스" 라 함은 회사가 제공하는 기본형 서비스를 회원이 회사에 일정 금액을 결제하거나, 제3자가 회원을 위해 서비스 이용료를 결제한 후 이용할 수 있는 회사의 서비스를 의미합니다.</div>
            <div className='margin' >2-2. "고급형 유료 서비스와 맞춤형 유료 서비스" 라 함은 회사가 제공하는 기본형 서비스를 회원이 회사에 일정 금액을 결제한 후 이용할 수 있는 회사의 서비스를 의미합니다.</div>
            <div className='margin' >3. "부가 유료 서비스" 라 함은 2-1, 2-2의 유료서비스와 별개로 회원이 별도로 신청 해야만 사용할 수 있는 회사(공인노무사 자문 및 고용유지원금 대행 및 대리 등 공인노무사법상 공인노무사의 직무)의 서비스를 의미합니다.</div></h4>
          <h4 style={{marginBottom: '-20px'}}><span style={{color: 'blue'}}>개정내용(변경후)</span>삭제</h4>
          <h4 ><span style={{color: 'blue'}}>개정사유</span>서비스 명칭이 회사 정책에 따라 수시로 변해서 이를 삭제함.</h4>
          <button style={{fontSize : '15px', color: 'black', height: '30px', width: '200px', background: 'white'}} onClick={() => setOpenChangeClientUseContaract(!openChangeClientUseContract)}>내용 닫기</button>
          <hr />
        </div>
      } */}

			{
				<Grid>
					<Grid.Column width={16}>
						<>
							<Button
								color='blue'
								onClick={() => history.push('/clientReqs4In')}>
								메인페이지
							</Button>
							<Button color='facebook' onClick={() => goBack()}>
								이전페이지
							</Button>
							<br />
							<br />
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									alignContent: 'center',
								}}
								onClick={() => setOpenWhatsNew(!openWhatsNew)}>
								<span
									style={{
										fontSize: '23px',
										color: 'darkBlue',
										cursor: 'pointer',
									}}>
									최근 SafeHR.{' '}
									<span style={{ color: 'red' }}> 업데이트 현황</span>
								</span>
							</div>
							<br />
							{openWhatsNew && (
								<>
									<ClientDashNewFunction
										setOpenWhatsNew={setOpenWhatsNew}
										openWhatsNew={openWhatsNew}
									/>
								</>
							)}
						</>
					</Grid.Column>
					<Grid.Column width={16}>
						<Loader active={loading} />
					</Grid.Column>
				</Grid>
			}
		</>
	);
}
