import React from 'react';
import { Table } from 'semantic-ui-react';

function ClientWageTableTop4DeductExplain({
	values,
	worker,
	handelInputChange,
	centerStyle,
	payPaperRetireYTOpen,
	payPaperMinusMoneyYTOpen,
	underInOut5daysWorker,
	centerStyleInOutBack,
	perDayMealTaxFree,
	centerStyleTeal,
	isRetireMonth,
	monthLBTime,
	hourOrdinaryWage,
	workerEnteredDate,
	workerRetiredDate,
	startToEndDate,
	payDay,
}) {
	return (
		<>
			<Table.Row>
				{values.plusRegularBonus ? (
					<Table.Cell
						textAlign='center'
						colSpan='6'
						className='gubun'
						style={centerStyle}>
						<input
							style={{ width: '100%' }}
							name='plusRegularBonusComment'
							placeholder='입사든 퇴사든 일할계산하지 않고 재직자에 한하여 지급하는 정기상여금 설명(계산방법)'
							value={values.plusRegularBonusComment}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				) : null}
				{values.plusIrregularBonus ? (
					<Table.Cell
						textAlign='center'
						colSpan='6'
						className='gubun'
						style={centerStyle}>
						<input
							style={{ width: '100%' }}
							name='plusIrregularBonusComment'
							placeholder='입사든 퇴사든 일할계산하지 않고 재직자에 한하여 지급하는 비정기 상여금 설명(계산방법)'
							value={values.plusIrregularBonusComment}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				) : null}
			</Table.Row>
			<Table.Row>
				{values.plusLastMonthAverWage ? (
					<Table.Cell
						textAlign='center'
						colSpan='6'
						className='gubun'
						style={centerStyle}>
						<input
							style={{ width: '100%' }}
							name='plusLastMonthAverWageComment'
							placeholder='지난 달 등 과거 급여계산에 착오가 있었던 경우'
							value={values.plusLastMonthAverWageComment}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				) : null}
			</Table.Row>
			<Table.Row>
				{values.plusPersonalIncentive ? (
					<Table.Cell
						textAlign='center'
						colSpan='6'
						className='gubun'
						style={centerStyle}>
						<input
							style={{ width: '100%' }}
							name='plusPersonalIncentiveComment'
							placeholder='개인실적 인센티브 설명(계산방법)'
							value={values.plusPersonalIncentiveComment}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'
						/>
					</Table.Cell>
				) : null}
			</Table.Row>
			<Table.Row>
				<Table.Cell textAlign='left' colSpan='9'>
					<span
						style={{
							fontSize: '1.2rem',
							color: 'darkcyan',
							fontWeight: 'bold',
						}}>{`3-2. ${worker}의 추가 공제금액 내역(금액기준)`}</span>
					{isRetireMonth ? (
						<span
							style={{
								position: 'absolute',
								right: 0,
								marginRight: '30px',
								cursor: 'pointer',
							}}>
							{/* <span
								style={{ color: 'red' }}
								onClick={() => payPaperRetireYTOpen()}>
								<Icon size='big' name='youtube' />
								동영상 설명
							</span> */}
						</span>
					) : (
						<span
							style={{
								position: 'absolute',
								right: 0,
								marginRight: '30px',
								cursor: 'pointer',
							}}>
							{/* <span
								style={{ color: 'red' }}
								onClick={() => payPaperMinusMoneyYTOpen()}>
								<Icon size='big' name='youtube' />
								공제금액 설명
							</span> */}
						</span>
					)}
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					건강보험 <br /> 정산분
				</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					장기요양보험
					<br /> 정산분
				</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					근로소득세
					<br /> 정산분
				</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					지방소득세
					<br /> 정산분
				</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					대여금 <br /> 반제
				</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					두루누리
					<br /> 지원금
				</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					공제 조정 <br />
					(4대 과오납
					<br /> 선지급공제 등)
				</Table.Cell>
				{isRetireMonth ? (
					<>
						<Table.Cell
							textAlign='center'
							className='gubun'
							style={centerStyle}>
							건보
							<br />
							퇴직정산
						</Table.Cell>
						<Table.Cell
							textAlign='center'
							className='gubun'
							style={centerStyle}>
							요양보험
							<br />
							퇴직정산
						</Table.Cell>
					</>
				) : (
					<>
						<Table.Cell textAlign='center'></Table.Cell>
						<Table.Cell textAlign='center'></Table.Cell>
					</>
				)}
			</Table.Row>
		</>
	);
}

export default ClientWageTableTop4DeductExplain;
