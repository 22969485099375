import React from 'react';
import { Table } from 'semantic-ui-react';

export default function ContactViewListItem4In({
	firstConfirmedTimeOfLaborContract,
	firstConfirmedTimeOfWageContract,
	contractMakeDate,
	formatDateWithMoment,
	contractChangeDate,
	lastConfirmedTimeOfLaborContract,
	lastConfirmedTimeOfWageContract,
}) {
	return (
		<>
			<hr />
			<strong>근로계약서 & 임금계약서 열람현황</strong>
			{firstConfirmedTimeOfLaborContract || firstConfirmedTimeOfWageContract ? (
				<>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>최초계약서작성일</Table.HeaderCell>
								<Table.HeaderCell>
									근로계약서 <br /> 최초열람일시
								</Table.HeaderCell>
								<Table.HeaderCell>
									임금계약서 <br /> 최초열람일시
								</Table.HeaderCell>
								<Table.HeaderCell>계약서최근변경일</Table.HeaderCell>
								<Table.HeaderCell>
									근로계약서 <br /> 최종열람일시
								</Table.HeaderCell>
								<Table.HeaderCell>
									임금계약서 <br /> 최종열람일시
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell>{contractMakeDate}</Table.Cell>
								<Table.Cell>
									{firstConfirmedTimeOfLaborContract
										? formatDateWithMoment(
												new Date(firstConfirmedTimeOfLaborContract)
										  )
										: ''}
								</Table.Cell>
								<Table.Cell>
									{firstConfirmedTimeOfWageContract
										? formatDateWithMoment(
												new Date(firstConfirmedTimeOfWageContract)
										  )
										: ''}
								</Table.Cell>
								<Table.Cell>
									{contractChangeDate ? contractChangeDate : ''}
								</Table.Cell>
								<Table.Cell>
									{lastConfirmedTimeOfLaborContract
										? formatDateWithMoment(
												new Date(lastConfirmedTimeOfLaborContract)
										  )
										: ''}
								</Table.Cell>
								<Table.Cell>
									{lastConfirmedTimeOfWageContract
										? formatDateWithMoment(
												new Date(lastConfirmedTimeOfWageContract)
										  )
										: ''}
								</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
					<p style={{ marginTop: '15px', color: 'blue' }}>
						* 직원이 열람한 근로조건(근로계약서 등)은 삭제되지 않습니다.
					</p>
				</>
			) : (
				<p style={{ marginTop: '15px', color: 'blue' }}>
					* SafeHR는 근로자 로그인 기능으로 근로계약서와 임금명세서 교부의무가
					이행됩니다. 로그인한 직원이 근로계약서 열람시 열람 현황이 나타나며,
					직원이 열람한 근로조건(근로계약서 등)은 삭제되지 않습니다.{' '}
				</p>
			)}
		</>
	);
}
