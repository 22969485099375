import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function PayPaperList() {
	const opts = {
		// height: '300%',
		width: '100%',
		playVars: {
			autoplay: 1,
		},
	};

	return (
		<ModalWrapper
			size='large'
			header='SafeHR 임금명세서 월별/개인별 임금대장 보기'>
			<YouTube videoId='AClBfzZLQso' opts={opts} />
		</ModalWrapper>
	);
}
