import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { listenToClientReq4InFromFirestore } from '../../../../app/firestore/firestoreService4In';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
	listenToSelectedClientReq4In,
} from '../clientReqActions4In';
import ClientReqDetailedHeader4In from './ClientReqDetailedHeader4In';
// import ClientReqDetailedSidbar from './ClientReqDetailedSidbar';
import ClientReqDetailedLaborContract from './ClientReqDetailedLaborContract';
import ClientReqDLCJudge from './clientReqDetailedLCDetail/ClientReqDLCJudge';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import { numStringToNumber } from '../../../../app/common/util/util';

export default function ClientReqDetailedPage4In({ match }) {
	const dispatch = useDispatch();
	const { selectedClientReq4In, clientReqs4In } = useSelector(
		(state) => state.clientReq4InStore
	);
	const { clientInfo, filter } = useSelector((state) => state.companyReducer);
	const { loading, error } = useSelector((state) => state.async);
	const { currentUserProfile } = useSelector((state) => state.profile);

	//노무사는 노무사가 입력한 회사로 회사이름 설정 가능하고, 유저는 본인 회원가입한 상호로만 회사이름 설정됨.
	const isWorker = currentUserProfile?.isWorker;
	const isEtcWorker = selectedClientReq4In?.isEtcWorker;

	const companyName = isWorker
		? currentUserProfile?.workerInputCompanyName
			? currentUserProfile?.workerInputCompanyName
			: selectedClientReq4In?.companyName
		: clientInfo?.companyName
		? clientInfo?.companyName
		: selectedClientReq4In?.company?.companyName
		? selectedClientReq4In?.company?.companyName
		: selectedClientReq4In?.companyName;

	const weekLBTime = selectedClientReq4In?.lawBase?.weekLBTime;

	useFirestoreDoc({
		query: () => listenToClientReq4InFromFirestore(match.params.id),
		data: (selectedClientReq4In) =>
			dispatch(listenToSelectedClientReq4In(selectedClientReq4In)),
		deps: [match.params.id, dispatch],
	});
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;

	useEffect(() => {
		dispatch(fetchClientReqs4In(filter)).then(() => {});
		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	const daylyTimePay = selectedClientReq4In?.daylyPay?.timeWage
		? numStringToNumber(selectedClientReq4In?.daylyPay?.timeWage)
		: 0;
	const isDayOrTimeWorker =
		selectedClientReq4In?.dayWorker?.daylyPay || daylyTimePay ? true : false;

	const superAdminTrue = currentUserProfile?.authLevel > 99 ? true : false;

	if (loading || (!selectedClientReq4In && !error))
		return <LoadingComponent content='Loading SafeHR ...' />;
	if (error) return <Redirect to='/error' />;

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}
			<Grid>
				<Grid.Column width={16}>
					<ClientReqDetailedHeader4In
						selectedClientReq4In={selectedClientReq4In}
						companyName={companyName}
						authLevel={authLevel}
						clientReqs4In={clientReqs4In}
						isDayOrTimeWorker={isDayOrTimeWorker}
					/>
					<ClientReqDLCJudge
						selectedClientReq4In={selectedClientReq4In}
						isEtcWorker={isEtcWorker}
						superAdminTrue={superAdminTrue}
					/>
					<ClientReqDetailedLaborContract
						clientInfo={clientInfo}
						selectedClientReq4In={selectedClientReq4In}
						currentUserProfile={currentUserProfile}
						companyName={companyName}
						weekLBTime={weekLBTime}
						isEtcWorker={isEtcWorker}
					/>
				</Grid.Column>
				{/* <Grid.Column width={1}>
          <ClientReqDetailedSidbar />
        </Grid.Column> */}
			</Grid>
		</>
	);
}
