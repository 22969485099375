import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from 'semantic-ui-react';
import { freeComClient } from '../../../app/common/util/util';

export default function PaydocuPrintOneTable({
	htmlContentRef,
	worker,
	month,
	year,
	isPayDocuDetailedPage,
	isRetireFund,
	isPersonalInfoAgree,
	isRuleObeyAgree,
	clientInfo,
	currentUserProfile,
	superAdminForFreeTrue,
	isLawBaseWorkerInfo,
	isBizWorker,
	companyName,
}) {
	const downloadHtmlAsPdf = async () => {
		const htmlContent = htmlContentRef.current;
		const textPaydocu =
			companyName + '의 ' + worker + year + ' 년' + month + '월 임금명세서.pdf';
		const textRetireFund = companyName + '의 ' + worker + ' 퇴직금 명세서.pdf';
		const textPersonalInfoAgree =
			companyName + '의 ' + worker + ' 개인정보 제공 수집 이용 활용 동의서.pdf';
		const textRuleAgree = companyName + '의 ' + worker + ' 복무서약서.pdf';
		const textLawBaseWorkerInfo =
			companyName + '의 ' + worker + ' 근로자명부.pdf';
		const textIsBizWorker =
			companyName + '의 ' + worker + ' 사업소득명세서.pdf';

		const outputFilename = isPayDocuDetailedPage
			? textPaydocu
			: isRetireFund
			? textRetireFund
			: isPersonalInfoAgree
			? textPersonalInfoAgree
			: isRuleObeyAgree
			? textRuleAgree
			: isLawBaseWorkerInfo
			? textLawBaseWorkerInfo
			: isBizWorker
			? textIsBizWorker
			: 'pdf파일';

		// html2canvas를 사용해 HTML 콘텐츠를 캔버스로 변환합니다.
		const canvas = await html2canvas(htmlContent, {
			scale: 2, // 필요에 따라 스케일을 조정합니다.
		});

		// 캔버스의 너비와 높이를 가져옵니다.
		const imgWidth = canvas.width;
		const imgHeight = canvas.height;

		// A4 크기 설정 (595.28 x 841.89 pt)
		const pdfWidth = 595.28;
		const pdfHeight = 841.89;

		// 비율에 맞춰 이미지 크기 조정
		const imgAspect = imgWidth / imgHeight;
		// const pdfAspect = pdfWidth / pdfHeight;

		// let imgPrintWidth, imgPrintHeight;

		// if (imgAspect <= 0 || pdfAspect <= 0) {
		// 	throw new Error('Aspect ratios must be positive values.');
		// }

		// if (pdfWidth <= 0 || pdfHeight <= 0) {
		// 	throw new Error('PDF dimensions must be positive values.');
		// }

		// if (imgAspect > pdfAspect) {
		// 	imgPrintWidth = pdfWidth;
		// 	imgPrintHeight = pdfWidth / imgAspect;
		// } else {
		// 	imgPrintHeight = pdfHeight;
		// 	imgPrintWidth = pdfHeight * imgAspect;
		// }

		// 여백 설정
		const margin = 20; // pt

		// jsPDF 객체를 생성합니다.
		const pdf = new jsPDF({
			orientation: 'p', // 고정된 portrait 방향
			unit: 'pt',
			format: [pdfWidth, pdfHeight],
		});

		// 캔버스를 이미지 데이터로 변환합니다.
		const imgData = canvas.toDataURL('image/jpeg', 1.0);

		// 이미지 데이터를 PDF에 추가합니다.
		pdf.addImage(
			imgData,
			'JPEG',
			margin, // X 좌표 (여백 설정)
			margin, // Y 좌표 (여백 설정)
			pdfWidth - margin * 2, // 이미지 너비 (여백을 뺀 값)
			(pdfWidth - margin * 2) / imgAspect // 이미지 높이 (여백을 뺀 값 비율)
		);

		const pdfBlob = new Blob([pdf.output('blob')], { type: 'application/pdf' });

		// Download link 생성
		const url = URL.createObjectURL(pdfBlob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', outputFilename); // 링크에 다운로드 속성을 추가하여 파일 이름을 설정합니다.
		document.body.appendChild(link); // 링크를 문서의 body에 추가합니다.
		link.click(); // 링크를 클릭하여 파일 다운로드를 시작합니다.
		link.parentNode.removeChild(link); // 링크를 문서에서 제거합니다.
	};

	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const ceoName = clientInfo?.ceoName
		? clientInfo?.ceoName
		: currentUserProfile?.ceoName;

	const isWorker = currentUserProfile?.isWorker;

	const freeDeleteDisable =
		superAdminForFreeTrue || isWorker
			? false
			: freeComClient(authLevel, ceoName)
			? true
			: false;

	return (
		<Button
			type='button'
			disabled={freeDeleteDisable}
			color='purple'
			onClick={() => downloadHtmlAsPdf()}>
			PDF 다운로드
		</Button>
	);
}
